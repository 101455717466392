import React, { useEffect, useState } from 'react';
import { 
    Box,    
    Icon,
    Button,
    Alert,
    AlertIcon, 
    FormControl,
    Input,
    SimpleGrid,
    Text,
    VStack,
    InputGroup,
    InputLeftElement} from '@chakra-ui/react';
import { RiEyeOffFill, RiEyeFill} from 'react-icons/ri';

export const RecoveryWord = (props) => {
    const [show, setShow] = useState(false)
    return (
        <InputGroup>
            <InputLeftElement color="gray.500" children={(props.index + 1)} />
            <Input variant="flushed" type={show || props.show ? 'text' : 'password'} value={props.word} onMouseEnter={() => setShow(true)}  onMouseLeave={() => setShow(false)}/>
        </InputGroup>
    )
}

const Recovery = (props) => {    
    const [allowProceed, setAllowProceed] = useState(false)
    const [timer, setTimer] = useState(30)
    const [show, setShow] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            if (timer === 0) {
                setAllowProceed(true);
                return true
            }
            setTimer(timer-1)
        }, 1000)
    }, [timer])

    

    return (  
        <FormControl isRequired>
            <Box textAlign="center">
                <VStack spacing={2} mb={2}>
                    <Alert status="warning" variant="left-accent">
                        <AlertIcon />
                        Please write down this 24-word recovery phrase in its correct order and store it in a safe place.
                        <br/>
                    </Alert>
                    <Alert status="info" variant="left-accent">
                        <AlertIcon />
                        <Text>In case you ever forget your master passphrase, you can use the below recovery phrase to regain access to your account.</Text>
                    </Alert>                    
                </VStack>
                {<Button variant="ghost" leftIcon={<Icon as={show ? RiEyeOffFill : RiEyeFill}/>} onClick={() => setShow(!show)}>{show ? "Hide All" : "Show All"}</Button>}
                <SimpleGrid mt={2} minChildWidth="100px" spacing="10px" >{props.mnemonic.split(' ').map((word, index) => <RecoveryWord key={index} index={index} word={word} show={show} />)}</SimpleGrid>                
            </Box>            
        </FormControl>
    )
}

export default Recovery;