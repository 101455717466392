import React from 'react';
import { useColorMode, useColorModeValue, IconButton, Icon, Switch, Tooltip } from '@chakra-ui/react';
import { RiSunLine, RiMoonLine  } from 'react-icons/ri';

export const ColorModeSwitcher = props => {
  const { colorMode, toggleColorMode } = useColorMode();
  
  const text = useColorModeValue('dark', 'light');
  

  return (   
    
    <IconButton
      size="md"
      fontSize="lg"
      aria-label={`Switch to ${text} mode`}
      variant="ghost"
      color="current"
      marginLeft="2"
      onClick={toggleColorMode}
      icon={<Icon as={colorMode === 'dark' ? RiSunLine : RiMoonLine} w={6} h={6}/>}
      {...props}
    />

      
  );
};
