import React from 'react'
import vault from './testVault.json'

export const VaultContext = React.createContext({
    data: {
        vaultId:0,
        items:[],
        folders:vault.folders,    
    },    
    addItem: (item) => {},
    deleteItem: (item) => {},
    editItem: (item) => {}
});