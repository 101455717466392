import { mode } from '@chakra-ui/theme-tools';
import { extendTheme } from '@chakra-ui/react';

const styles = {
  global: props => ({
    body: {
      color: mode('gray.800', 'whiteAlpha.900')(props),
      bg: mode('white', '#1a1b20')(props),
    },
  }),
};

const components = {
  Drawer: {
    // setup light/gray.200 mode component defaults
    baseStyle: props => ({
      dialog: {
        bg: mode('white', '#1a1b20')(props),
      },
    }),
  },
  Modal: {
    // setup light/gray.200 mode component defaults
    baseStyle: props => ({
      dialog: {
        bg: mode('white', '#1a1b20')(props),
      },
    }),
  },
  Button: {
    // setup light/dark mode component defaults
    baseStyle: props => ({
      borderRadius:'2px'
    }),
  }, 
};

const theme = extendTheme({
  colors: {
    brand: {
      50: 'linear-gradient(90deg, #dafff0 0%, #d8ffff 100%)',
      100: 'linear-gradient(90deg, #adffd9 0%, #acf8ff 100%)',
      200: 'linear-gradient(90deg, #7cffc2 0%, #7df2ff 100%)',
      300: 'linear-gradient(90deg, #4affaa 0%, #4dedff 100%)',
      400: 'linear-gradient(90deg, #1aff93 0%, #27e8fe 100%)',
      500: 'linear-gradient(90deg, #00e679 0%, #17cee5 100%)',
      600: 'linear-gradient(90deg, #00b35d 0%, #00a0b3 100%)',
      700: 'linear-gradient(90deg, #008042 0%, #007381 100%)',
      800: 'linear-gradient(90deg, #004e26 0%, #00464f 100%)',
      900: 'linear-gradient(90deg, #001c08 0%, #00191e 100%)',
    }
  },
  fonts: {
    heading: "Roboto",
    body: "Roboto",
  },
  components,
  styles,
});

export default theme;
