export const copyText = (text) => {
    navigator.clipboard.writeText(text).then(
        function () {
                /* clipboard successfully set */
                return true
        },
        function () {          
                /* clipboard write failed */
                return false
        }
    );
}

String.prototype.toTitleCase = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};
