import React, { useState } from 'react';
import { 
    Box,
    Input, 
    InputGroup, 
    InputLeftElement,    
    Icon,
    Button, 
    FormControl,
    Progress,    
    VStack,
    FormLabel,
    useToast,
    InputRightElement,
    Stack,
    Alert, 
    AlertIcon,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatGroup,
    AlertTitle,
    AlertDescription
} from '@chakra-ui/react';
import { RiKeyFill, RiEyeOffFill, RiEyeFill } from 'react-icons/ri';
import { toTitleCase } from './../../helpers/utils'

const Secrets = (props) => {    
    const [show1, setShow1] = useState(false)
    const [show2, setShow2] = useState(false)
    const toast = useToast()

    const crackTimeTag = time => {
        return (
            <StatGroup>
                <Stat>
                    <StatLabel>Time to crack:</StatLabel>
                    <StatNumber>{time.toTitleCase()}</StatNumber>
                    <StatHelpText fontSize="xs" color="gray.500">(At the speed of 600 million guesses per second.)</StatHelpText>
                </Stat>
            </StatGroup>
        )
    }   

    const suggestionAlert = suggestion => {
        return (
            <Alert status="info" variant="left-accent">
                <AlertIcon />
                <Box flex="1">
                    <AlertTitle>Hint</AlertTitle>
                    <AlertDescription>{suggestion}</AlertDescription>
                </Box>
            </Alert>
        )
    }

    const warningAlert = warning => {
        return (
            <Alert status="warning" variant="left-accent">
                <AlertIcon />
                <Box flex="1">
                    <AlertTitle>Warning</AlertTitle>
                    <AlertDescription>{warning}</AlertDescription>
                </Box>
            </Alert>
        )
    }

    const dynamicHints = () => {
        return (
            <VStack spacing={2} mb={2}>
                {props.strength?.feedback?.suggestions?.map(suggestion => suggestionAlert(suggestion))}
                {props.strength?.feedback?.warning && warningAlert(props.strength.feedback.warning)}
            </VStack>
        )
    }

    const staticHints = () => {
        return (
            <VStack spacing={2} mb={2}>
                <Alert status="info">                    
                    Hint: A long sentence that's easy for you to remember, but hard for a bad guy's computer to guess.
                </Alert>
                <Alert status="info">
                    <AlertIcon />
                    Hint: Think about a line from your favorite movie, a memorable quote from a book or lyrics from your favorite song.
                </Alert>
            </VStack>
        )
    }

    const handlePaste = event => {
        event.preventDefault();
        toast({
            title: "Error",
            description: "Please re-type the password manually!",
            status: "error",
            duration: 5000,
            isClosable: true,
            position:"bottom-right"
        }) 
        return false
    }


    return (
        
        <Stack spacing={4} w="full">
            <FormControl isRequired>
                <FormLabel>Passphrase</FormLabel>
                <InputGroup>
                    <InputLeftElement children={<Icon as={RiKeyFill} />}/>
                    <Input variant="filled" autoComplete="off" type={show1 ? "text" : "password"} placeholder='Passphrase' aria-label='Password' value={props?.password} onChange={((event) => props?.setPassword(event.target.value))}/>
                    <InputRightElement>
                        <Button variant="ghost" size='md' onClick={() => setShow1(!show1)} >
                        {show1 ? <Icon as={RiEyeOffFill}/> : <Icon as={RiEyeFill}/>}
                        </Button>
                    </InputRightElement>
                </InputGroup>
            </FormControl>
            <FormControl isRequired>
                <FormLabel>Re-type your passphrase</FormLabel>
                <InputGroup>
                    <InputLeftElement children={<Icon as={RiKeyFill} />}/>
                    <Input variant="filled" autoComplete="off"  onPaste={handlePaste} type={show2 ? "text" : "password"} placeholder='Confirm Passphrase' aria-label='Confirm Passphrase' value={props.password2} onChange={((event) => props?.setPassword2(event.target.value))}/>
                    <InputRightElement>
                        <Button variant="ghost" size='md' onClick={() => setShow2(!show2)} >
                        {show2 ? <Icon as={RiEyeOffFill}/> : <Icon as={RiEyeFill}/>}
                        </Button>
                    </InputRightElement>
                </InputGroup>
            </FormControl> 
            <Box p={2}>
                {props.password && dynamicHints()}    
                {props.strength?.crack_times_display && crackTimeTag(props.strength?.crack_times_display.offline_slow_hashing_1e4_per_second)}
                <Progress size="md" colorScheme={props.strengthColor} value={props.password.length  === 0 ? 0 : (props.strength.score+1)* 20} mt={2}/>                            
            </Box> 
        </Stack>
        
    )
}

export default Secrets;