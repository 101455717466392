import React, { useState } from 'react'
import { 
    Box, 
    Heading,
    HStack,
    Icon,
    Text
} from '@chakra-ui/react'
import  { markdown } from './../helpers/markdown'
import { RiEditFill, RiStickyNoteFill } from 'react-icons/ri'

const NoteCard = (props) => {
    const note = markdown(props.item.note)
    return (
        <Box w={'100%'} h="xs" maxW="lg" transition="all 0.2s"  rounded='2px'  overflow='hidden' boxShadow='sm' _hover={{ boxShadow: 'lg' }} bg={props.item?.color?.bg || 'gray.500'} color={props.item?.color?.text || 'white'}>
            <HStack w="full" p={2} justify="space-between" as="button" onClick={() => props?.view(props?.item)}>   
                <Heading size="md" ><Icon mr={2} as={RiStickyNoteFill} />{props.item.name}</Heading>
                <Icon as={RiEditFill} />
            </HStack>
            <Box p={2} className="prose">               
               <Text fontSize="xs" textAlign="left" isTruncated whiteSpace="pre-wrap" dangerouslySetInnerHTML={{ __html: note }}></Text>
            </Box>
        </Box>
    )
}

export default NoteCard