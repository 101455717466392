import { useContext, useEffect, useState } from 'react'
import { Input, InputGroup, InputLeftElement, InputRightElement, Stack, Icon, Button, FormControl, useToast, useDisclosure } from '@chakra-ui/react'
import { RiShieldUserFill, RiKeyFill, RiEyeFill, RiEyeOffFill, RiLoginBoxFill, RiLockUnlockFill } from 'react-icons/ri'
import { postData } from '../helpers/fetchUtils'
import { addLocalAccount, passwordHash, getUserKey, getMasterKey, decryptProtectedRecovery, getProtectedRecovery, mnemonicHash } from '../helpers/authUtils'
import { Redirect } from 'react-router-dom'
import { AuthContext } from './Context'
import LoginMnemonic from './LoginMnemonic'

const LoginForm = (props) => {
    const context = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const [loadingText, setLoadingText] = useState('Logging in...')
    const [username, setUsername] = useState(props?.account?.username || '')
    const [email, setEmail] = useState(props?.account?.email || '')
    const [protectedRecovery, setProtectedRecovery] = useState(props?.account?.protectedRecovery || undefined)
    const [password, setPassword] = useState('')    
    const [mnemonicIntegrity, setMnemonicIntegrity]= useState('')
    const [showPassword, setShowPassword] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()

    const handleToggleShow = () => setShowPassword(!showPassword)
    const handleEmailChange = (event) => setEmail(event.target.value)
    const handlePasswordChange = (event) => setPassword(event.target.value)

    

    const handleRecoveryInput = async (recovery, event) => {
        const typedMnemonicHash = await mnemonicHash(recovery)
        if (typedMnemonicHash !== mnemonicIntegrity) {
            toast({
                title: "Error",
                description: "Incorrect recovery phrase!",
                status: "error",
                duration: 2000,
                isClosable: true,
                position:"bottom-right"
            }) 
            return false;
        }
        await getUserKey(email, password).then(async userKey => {
            await getProtectedRecovery (userKey, recovery).then(computedProtectedRecovery => {
                setProtectedRecovery(computedProtectedRecovery);
                onClose();
                //console.log('updated recovery:', computedProtectedRecovery)
                //return login(event)
            })
        });
    }

    const handleSubmit = async event => {
        //console.log('handlesubmit')
        event.preventDefault();
        return await login()
    }

    const login = async () => {        
        if (password === '') return false;
        setLoading(true);
        try {
            await passwordHash(password, email).then(async passwordHash => {     
                const loginResponse = await postData('/login/',{email:email, password: passwordHash.encoded,});       
                
                if (loginResponse.refresh && loginResponse.access) {
                    
                    //console.log('existing recovery', protectedRecovery)
                    //setLoading(false)                    
                    const jwt_payload = JSON.parse(atob(loginResponse.access.split('.')[1]))                    
                    setMnemonicIntegrity(jwt_payload.recovery_hash);
                    if (!protectedRecovery) { 
                        if (jwt_payload.recovery_key){
                            //console.log('found a saved recovery')
                            setProtectedRecovery(jwt_payload.recovery_key)                                                
                        }
                        else {
                            onOpen();
                            return false;
                        }
                    }
                    
                    return await protectedRecovery && startSession(loginResponse);
                                 
                }    
                else {
                    toast({
                        title: "Error",
                        description: loginResponse.detail,
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                        position:"bottom-right"
                    }) 
                    
                }    
            });
            
        } catch (err) {
            console.log(err)
            toast({
                title: "Error.",
                description: err.message,
                status: "error",
                duration: 2000,
                isClosable: true,
                position:"bottom-right"
            })    
        }
        setLoading(false);
        return false;
    }

    const startSession = async (tokens) => {            
        setLoadingText('Spinning up keys...')   
        const jwt_payload = JSON.parse(atob(tokens.access.split('.')[1]))                    
        await getUserKey(email, password).then(async userKey => {
            //console.log('userkey', userKey.hash)
            await decryptProtectedRecovery(userKey, protectedRecovery).then (async recovery => {
                //console.log('recovery', recovery)
                await getMasterKey(recovery, email).then (async masterKey => {
                    //console.log('masterkey', masterKey)
                    const userCrypto = {
                        userKey:userKey,
                        masterKey:masterKey
                    }
                    const newSession = { ...tokens, ...userCrypto, email: email}
                    //console.log('creating session:', newSession)                    
                    addLocalAccount({
                        email:jwt_payload.email,
                        username: jwt_payload.username,
                        protectedRecovery:protectedRecovery
                    })
                    toast({
                        title: "Success",
                        description: "Logged In!",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                        position:"bottom-right"
                    })
                    context.createSession(newSession)   
                })                            
            })
        })
        return;
    }

    useEffect(() => {
        if (!context.data && protectedRecovery) {            
            login()
        }
    },[protectedRecovery])

    if (isOpen) {
        return (<LoginMnemonic isOpen={isOpen} onOpen={onOpen} onClose={onClose} handleRecoveryInput={handleRecoveryInput}/>)
    }
    return (        
        <form onSubmit={handleSubmit} >
            <Stack spacing={4}>
                {!props?.account?.email && <FormControl isRequired>
                    <InputGroup>
                        <InputLeftElement children={<Icon mt="2" as={RiShieldUserFill} />}/>
                        <Input type='email' placeholder='Email' aria-label='Email' value={email} onChange={handleEmailChange} variant="filled" focusBorderColor="#61EFCE" size="lg"/>
                    </InputGroup>
                </FormControl>}
                <FormControl isRequired>
                    <InputGroup>
                        <InputLeftElement children={<Icon mt="2" as={RiKeyFill} />}/>
                        <Input type={showPassword ? 'text':'password'} autoComplete="off" placeholder='Passphrase' aria-label='Passphrase' value={password} onChange={handlePasswordChange} variant="filled" focusBorderColor="#61EFCE" size="lg"/>
                        <InputRightElement>
                            <Button variant="ghost" size='md' onClick={handleToggleShow} mt="2" >
                            {showPassword ? <Icon as={RiEyeOffFill}/> : <Icon as={RiEyeFill}/>}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </FormControl>
                
                <Button 
                    type='submit' 
                    variant='solid' 
                    colorScheme='brand'
                    isLoading={loading}      
                    loadingText={loadingText || 'Logging in...'}
                    rightIcon={props?.account?.email ? <Icon as={RiLockUnlockFill} /> : <Icon as={RiLoginBoxFill} />}
                    //onClick={login}              
                >{props?.account?.email ? "Unlock vault" : "Login"}</Button>
            </Stack>               
            
        </form>
    );
}

export default LoginForm