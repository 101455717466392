import React, { useContext, useEffect } from 'react'
import { Box, Divider, Heading, Text, VStack } from '@chakra-ui/react'
import { AuthContext } from './../../Auth/Context'
import { getLocalAccounts } from './../../helpers/authUtils'

const AccountInfo = () => {
    const context = useContext(AuthContext)
    const account = getLocalAccounts().find(account => account.email === context.data.email)
    
    return (
        <VStack>
            {Object.entries({...account}).map((value) => {
                return (
                    <Box w="2xl" textAlign="left">
                        <Heading size="md">{value[0].toTitleCase()}</Heading>
                        <Text fontSize="md" isTruncated>{value[1]}</Text>
                        <Divider m={4} />
                    </Box>
                )
            })}
        </VStack>
    )
}

export default AccountInfo;