import {
    Center,
    Icon,
    SimpleGrid,
    VStack,
    Text
} from '@chakra-ui/react'
import { VaultContext } from './vault-context'
import { useContext, useState, useEffect, useMemo } from 'react'
import CredentialCard from './CredentialCard'
import SkeletonCard from './SkeletonCard'
import { RiAdminFill } from 'react-icons/ri'

const Placeholder = () => {
    return (
        <Center w="full" h="xl" textAlign="center" verticalAlign="middle">
            <VStack>
                <Icon w={24} h={24} as={RiAdminFill} color="gray" opacity="0.5"/>
                <Text color="gray" opacity="0.5">No Logins yet</Text>
            </VStack>
        </Center>
    )
}

const LoginsView = (props) => {
    const context = useContext(VaultContext)
    const [items, setItems] = useState(context.data.items.filter((item) => item.itemType === 1))        
    
    const filterItems = () => {
        if (!props.search) {
            return context.data.items.filter((item) => item.itemType === 1);
        }
        
        const query = props.search.toLowerCase();
        return context.data.items.filter((item) => item.itemType === 1).filter((item) => {            
            return [...Object.values(item), ...item.tags, ...item.customInputs].find(v => {                
                try {
                    return v.toLowerCase().includes(query)
                } catch (err) {
                    return false
                }                
            })
        })
    }
    const filteredItems = useMemo(() => filterItems(), [props.search, context.data.items])

    useEffect(() => {
        setItems(filteredItems)
    }, [props.search, context.data.items])

    return (
        <SimpleGrid minChildWidth={'300px'} spacing="20px" h="full">            
            {(!props.skeletons && items?.length === 0) && <Placeholder />}
            {!props.skeletons && items?.map((item, index) => <CredentialCard cred={item} key={index} setSearch={props.setSearch} view={props?.view}  />)}            
            {props?.skeletons > 0 && new Array(props?.skeletons).fill('').map((index) => <SkeletonCard key={index}/>)}                
        </SimpleGrid>
    )
}

export default LoginsView;