//gim = Global case-Insensitive Multi-line
import * as SimpleIcons from "react-icons/si"
const simpleIconsApi = require('simple-icons');

export const detectCardType = number => {
    const types = {
        electron: {
            name: 'visa electron',
            regex: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
            icon:SimpleIcons.SiVisa,
            color:'#'+simpleIconsApi.get('Visa').hex
        },
        visa: {
            name: 'visa',
            regex: /^4[0-9]{12}(?:[0-9]{3})?$/,
            icon:SimpleIcons.SiVisa,
            color:'#'+simpleIconsApi.get('Visa').hex
        },
        mastercard: {
            name: 'mastercard',
            regex: /^5[1-5][0-9]{14}$/,
            icon:SimpleIcons.SiMastercard,
            color:'#'+simpleIconsApi.get('MasterCard').hex
        },
        amex: {
            name: 'american express',
            regex: /^3[47][0-9]{13}$/,
            icon:SimpleIcons.SiAmericanexpress,
            color:'#'+simpleIconsApi.get('American Express').hex
        },        
        discover: {
            name: 'discover',
            regex: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
            icon:SimpleIcons.SiDiscover,
            color:'#'+simpleIconsApi.get('Discover').hex
        },
        jcb: {
            name: 'jcb',
            regex: /^(?:2131|1800|35\d{3})\d{11}$/,
            icon:SimpleIcons.SiJcb,
            color:'#'+simpleIconsApi.get('JCB').hex
        }
    }

    for(let key in types) {
        if(types[key].regex.test(number)) {
            return types[key]
        }
    }
    return types.mastercard;
}