import React, { useState } from 'react';
import {
    Box,
    Button,
    FormLabel,
    Icon,
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightElement
} from '@chakra-ui/react';
import { RiDeleteBinFill, RiEyeFill, RiEyeOffFill } from 'react-icons/ri';

const CustomInputBox = (props) => {
    const [value, setValue] = useState('' || props?.value)
    const [hidden, setHidden] = useState(false)
    const handleChange = (event) => {
        setValue(event.target.value)
        props.update(props.index, event.target.value)        
    }
    return (
        <Box>
            <FormLabel htmlFor="name">{"Custom Field " + (props.index+1)}</FormLabel>
            <InputGroup>
                <Input
                    ref={props.ref}                
                    variant="filled"
                    placeholder=""
                    value={value}
                    onChange={handleChange}
                    type={hidden ? 'password' : 'text'}
                />
                <InputRightElement>
                    <Button onClick={() => setHidden(!hidden)} title={hidden ? 'Hidden' : 'Visible'}>
                        <Icon as={hidden ? RiEyeOffFill : RiEyeFill}/>
                    </Button>
                    <Button onClick={() => props.remove(props.index)} colorScheme="red" title="Delete Custom Field">
                        <Icon as={RiDeleteBinFill}/>
                    </Button>
                </InputRightElement>
            </InputGroup>
        </Box>
    )
}

export default CustomInputBox;