import {
    Center,
    Icon,
    SimpleGrid,
    VStack,
    Text
} from '@chakra-ui/react'
import { VaultContext } from './vault-context'
import { useContext, useState, useEffect, useMemo } from 'react'
import NoteCard from './NoteCard'
import SkeletonCard from './SkeletonCard'
import { RiAdminFill, RiPhoneLockFill } from 'react-icons/ri'

const Placeholder = () => {
    return (
        <Center w="full" h="xl" textAlign="center" verticalAlign="middle">
            <VStack>
                <Icon w={24} h={24} as={RiPhoneLockFill} color="gray" opacity="0.5"/>
                <Text color="gray" opacity="0.5">No Notes yet</Text>
            </VStack>
        </Center>
    )
}

const NotesView = (props) => {
    const context = useContext(VaultContext)
    const [items, setItems] = useState(context.data.items.filter((item) => item.itemType === 2))        
    
    const filterItems = () => {
        if (!props.search) {
            return context.data.items.filter((item) => item.itemType === 2);
        }
        const query = props.search.toLowerCase();
        return context.data.items.filter((item) => item.itemType === 2).filter((item) => {
            return item.name.toLowerCase().includes(query) || item.note.toLowerCase().includes(query) || (item.tags?.forEach(tag => {
                tag.toLowerCase().includes(query)                
            }))
        })
    }
    const filteredItems = useMemo(() => filterItems(), [props.search, context.data.items])

        
    useEffect(() => {
        setItems(filteredItems)
    }, [props.search, context.data.items])

    return (
        <SimpleGrid minChildWidth={'300px'} spacing="20px" h='full'>        
            {items?.length === 0 && <Placeholder/>}    
            {items.map((item, index) => <NoteCard key={index} item={item} view={props.view} />)}            
        </SimpleGrid>
    )
}

export default NotesView;