import { authenticator, totp } from 'otplib';

const createSecret = (size = 32) => {
    console.log('create secret')
    let buffer = new Uint32Array(size)
    window.crypto.getRandomValues(buffer);
    console.log(buffer)
    return buffer;
}

export const getTOTP = (secret) => {      
    if (!secret) return false;
    const OTP = authenticator.generate(secret);
    console.log(secret, ' -> ', OTP)
    return {
        secret: secret,
        OTP: OTP        
    }
}

export const createTOTP = () => {
    const secret = createSecret()
    console.log('secret', secret)
    return {
        secret: secret        
    }

}

export const verifyTOTP = (OTP, secret) => {
    const isValid = totp.check(OTP, secret);
    const isVerified = totp.verify({ OTP, secret });
    return {
        check: isValid,
        verify: isVerified
    }
}

export  const getTimer = () => {
    return  totp.timeRemaining();
}