//Regex patterns to detecte markdown syntax.  gim = Global case-Insensitive Multi-line
const h1 = /^# (.*$)/gim;
const h2 = /^## (.*$)/gim;
const h3 = /^### (.*$)/gim;
const h4 = /^#### (.*$)/gim;
const h5 = /^##### (.*$)/gim;
const h6 = /^###### (.*$)/gim;
const hr = /^---(.*)/gim;
const br = /^\s\s(.*$)/gim;//not working
const blockquote = /^> (.*$)/gim;
const ul = /^- (.*$)/gim;
const code = /`(.*)`/gim;
const bold = /\*\*(.*)\*\*/gim;
const italic = /\*(.*)\*/gim;
const img = /!\[(.*?)\]\((.*?)\)/gim;
const link = /\[(.*?)\]\((.*?)\)/gim;
const linebreak = /\n$/gim;

//replaces matched patterns with their html equivalent
export const markdown = input => {
    const rendered = input
    .replace(h1, '<h1>$1</h1>')
    .replace(h2, '<h2>$1</h2>')
    .replace(h3, '<h3>$1</h3>')
    .replace(h4, '<h4>$1</h4>')
    .replace(h5, '<h5>$1</h5>')
    .replace(h6, '<h6>$1</h6>')
    .replace(blockquote, '<blockquote>$1</blockquote>')
    .replace(code, '<p>    $1</p>')
    .replace(bold, '<b>$1</b>')
    .replace(italic, '<i>$1</i>')
    .replace(img, "<img alt='$1' src='$2' />")
    .replace(link, "<a href='$2'>$1</a>")
    .replace(linebreak, '<br />')
    .replace(ul, '<li>$1</li>')
    .replace(hr, '<hr/>')
    .replace(br, '<br/>$1')
    return rendered.trim();
}
