import React from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
  } from "@chakra-ui/react"
const Security = () => {
    return (
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Type</Th>
            <Th>Algorithm</Th>
            <Th>Parameters</Th>
            <Th>Interface</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Data Encryption</Td>
            <Td>AES-256 GCM</Td>
            <Td>256 Bit Key</Td>
            <Td>WebCrypto</Td>
          </Tr>
          <Tr>
            <Td>Key Derivation</Td>
            <Td>Argon2id</Td>
            <Td>M=31250 P=3 I=3</Td>
            <Td>WebAssembly</Td>
          </Tr>
          <Tr>
            <Td>Mnemonic Seed Key</Td>
            <Td>Bip39</Td>
            <Td>256 Bit Entropy</Td>
            <Td>Bip39</Td>
          </Tr>
        </Tbody>
      </Table>
    )
}

export default Security