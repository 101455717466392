import React from 'react'

export const AuthContext = React.createContext({
    data: {},        
    createSession: () => {},
    refreshSession: () => {},
    endSession: () => {},
    getData: () => {},
    postData: () => {},
    putData: () => {},
    deleteData: () => {}
});