import React, { useState, useEffect, useContext, useRef } from 'react'
import {
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    InputRightElement,
    InputLeftElement,
    Stack,
    FormLabel,
    Text,
    Textarea,
    Box,
    IconButton,
    Icon,
    Button,
    Switch,
    HStack,
    Divider,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,    
    DrawerBody,
    DrawerFooter,
    DrawerHeader,    
    DrawerContent,
    DrawerCloseButton,
    useToast,
    PinInput, 
    PinInputField,
    FormControl,
    Tag,
    TagLabel,
    TagCloseButton,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Progress,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,   
    StatGroup,
    CircularProgress,
    CircularProgressLabel,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    ButtonGroup,
} from '@chakra-ui/react'
import {
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
  } from "@chakra-ui/react"
import { Flex, Spacer } from "@chakra-ui/react"
import { RiRefreshLine, VscSettings, RiEyeFill, RiEyeOffFill, RiPencilFill, RiDeleteBinFill, RiTimer2Fill, RiTimerFill, RiFileCopyFill, RiCheckFill, RiEdit2Fill } from 'react-icons/ri'
import { generatePassword, encryptItem, decryptItem } from '../helpers/authUtils'
import { VaultContext } from './vault-context'
import { AuthContext } from './../Auth/Context'
import CustomInputBox from './CustomInputBox'
import { useClipboard } from "@chakra-ui/react"
import { brands, matchBrand } from './../helpers/brandUtils'
import { v4 as uuidv4 } from 'uuid';
import { createTOTP, getTOTP, getTimer } from './../helpers/otpUtils'

const TOTPContainer = (props) => {          
    const [otp, setOtp] = useState(undefined)
    const [timer, setTimer] = useState(getTimer)

    useEffect(() => {        
        const newOTP = getTOTP(props.totp.secret)                
        setOtp(newOTP)
    }, [])

    useEffect(() => {
        setTimer(getTimer())        
        setTimeout(() => {            
            if (timer === 30) {                
                setOtp(getTOTP(props.totp.secret));
                setTimer(getTimer())
                return true
            }
            //setTimer(timer-1)
            setTimer(getTimer())
        }, 1000)
    }, [props.totp.secret, timer])

    const { hasCopied, onCopy } = useClipboard(otp?.OTP || '')

    return (
        <Box>
            {otp && 
            <HStack justify="space-between">
                <StatGroup>
                    <Stat>
                        <StatLabel>TOTP</StatLabel>
                        <StatNumber>
                            {timer && <CircularProgress size="50px" m={3} color={timer > 10 ? "green" : timer > 5 ? "orange" : "red"} value={((timer / 30) * 100)}>
                                <CircularProgressLabel>{timer}</CircularProgressLabel>
                            </CircularProgress>}
                            {otp.OTP}
                        </StatNumber>
                        <StatHelpText>
                                         
                        </StatHelpText>
                    </Stat>
                </StatGroup>
                <ButtonGroup isAttached >
                    <Button rounded="md" rightIcon={<Icon as={hasCopied ? RiCheckFill : RiFileCopyFill}  />} onClick={onCopy} colorScheme={hasCopied ? "blue" : "gray"} size="md" justifySelf="flex-end" maxW="sm">
                        {hasCopied ? "Copied" : "Copy TOTP"}
                    </Button>  
                    <Button rounded="md" colorScheme="orange" onClick={() => props.view()} title="Edit TOTP">
                        <Icon as={RiEdit2Fill} />
                    </Button>                                 
                </ButtonGroup>
                
            </HStack>}
            
        </Box>
    )
}

const ItemTag = (props) => {
    return (
        <Tag
            size={'md'}                        
            variant="subtle"
            colorScheme="gray"
            >
            <TagLabel>{props.value}</TagLabel>
            <TagCloseButton onClick={() => props.remove(props.index)} />
        </Tag>
    )

}

const NewCredentialForm = (props) => {
    const context = useContext(VaultContext)
    
    const [dialogIsOpen, setDialogIsOpen] = React.useState(false)
    const onDrawerClose = () => setDialogIsOpen(false)
    const cancelRef = React.useRef()    
    
    const brand = brands.find(brand => matchBrand(brand, props?.item))
    const firstField = React.useRef()
    const [name, setName] = useState(props?.item?.name || '')
    const [username, setUsername] = useState(props?.item?.username || '')
    const [email, setEmail] = useState(props?.item?.email || '')
    const [site, setSite] = useState(props?.item?.site || '')    
    const [notes, setNotes] = useState(props?.item?.notes || '')
    const [uppercase, setUppercase] = useState(true)
    const [lowercase, setLowercase] = useState(true)
    const [numbers, setNumbers] = useState(true)
    const [symbols, setSymbols] = useState(true)
    const [length, setLength] = useState(32)
    const [password, setPassword] = useState(props?.item?.password || '')
    const [customInputs, setCustomInputs] = useState(props?.item?.customInputs || [])
    const [tags, setTags] = useState(props?.item?.tags || [])
    const [tagInput, setTagInput] = useState('')
    //const [totpSecret, setTotpSecret] = useState('' || props?.item?.totp?.secret)
    //const [totpBackups, setTotpBackups] = useState('' || props?.item?.totp?.backups)
    const [totp, setTotp] = useState(props?.item?.totp || undefined)    
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [showTotpSecret, setShowTotpSecret] = useState(false)
    
    const handlePasswordChange = (event) => setPassword(event.target.value)
    const [showPassword, setShowPassword] = useState(false)
    const handleToggleShow = () => setShowPassword(!showPassword)
    

    const setTotpSecret = value =>  {
        setTotp({...totp, secret: value})
    }

    const setTotpBackups = value =>  {
        setTotp({...totp, backups: value})
    }

    const deleteTotp = () => {
        setTotp(undefined)
        onClose();
    }
    
    const allowPWOptionToggle = () => {
        let onCount = 0;
        if (uppercase) onCount += 1;
        if (lowercase) onCount += 1;
        if (numbers) onCount += 1;
        if (symbols) onCount += 1;        
        return onCount > 1;
    }

    const handleSetUppercase = (event) => {
        const allow = allowPWOptionToggle();
        if (allow || event.target.checked) {
            setUppercase(event.target.checked)
        }
        return false;
    }
    const handleSetLowercase = (event) => {
        const allow = allowPWOptionToggle();
        if (allow || event.target.checked) {
            setLowercase(event.target.checked)
        }
        return false;
    }
    const handleSetNumbers = (event) => {
        const allow = allowPWOptionToggle();
        if (allow || event.target.checked) {
            setNumbers(event.target.checked)
        }
        return false;
    }
    const handleSetSymbols = (event) => {
        const allow = allowPWOptionToggle();
        if (allow || event.target.checked) {
            setSymbols(event.target.checked)
        }
        return false;
    }

    const handleSliderChange = value => setLength(value);
    
    const handleGenerateClick = () => {
        setPassword(generatePassword(length, uppercase, lowercase, numbers, symbols))
    }

    const handleAddCustomInput = () => {        
        setCustomInputs([...customInputs, ''])
    }

    const handleRemoveCustomInput = index => {        
        setCustomInputs(customInputs.filter((input, i) => i !== index))
    }

    const updateCustomInput = (index, value) => {
        setCustomInputs(customInputs.map((input, i) => {
            if (index === i) return value;
            return input;
        }))       
    }

    const handleAddTag = value => {        
        setTags([...tags, value])
        setTagInput('')
    }

    const handleRemoveTag = index => {            
        setTags(tags.filter((tag, i) => i !== index))
    }

    const submit = async (event) => {
        event.preventDefault();
        
        const newItem = {                   
            itemType: 1,
            name: name,            
            username: username,
            email: email,
            password: password,
            site: site,
            notes: notes,
            tags: tags,
            totp: totp,
            customInputs: customInputs
        }

        if (!props.item) {
            newItem.id = uuidv4() 
            context.addItem(newItem)            
        }

        else {
            newItem.id = props.item.id
            context.editItem(newItem)
        }
        
        props.onClose();
    }

    const handleDelete = (event) => {
        context.deleteItem(props.item)
        props.onClose();
    }
    
    const isInitialMount = useRef(true);

    useEffect(() => {   
        if (isInitialMount.current) {
            isInitialMount.current = false;            
        }
        else {
            setPassword(generatePassword(length, uppercase, lowercase, numbers, symbols))
        }     
        
    }, [length, uppercase, lowercase, numbers, symbols, props?.cred])

    const { hasCopied, onCopy } = useClipboard(password)

    

    return (
        <form onSubmit={submit}>
        <DrawerContent>
            
                <DrawerCloseButton color={props?.item ? "white" : "inherit"} />
                <DrawerHeader bg={props?.item ? (brand?.color || 'gray.600') : 'inherit'} color={props?.item ? "white" : "inherit"} >{props.item && <Icon mr={2} as={brand?.icon} />}{props?.item? props.item.name : "Create a new Item"}</DrawerHeader>

                <DrawerBody>
                    <Stack spacing="10px">
                        <Box>                            
                            <FormControl id="name" isRequired>
                                <FormLabel htmlFor="name">Name</FormLabel>
                                <Input
                                    ref={firstField}
                                    id="name"
                                    variant="filled"
                                    placeholder="Enter Item Name"
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}
                                />
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl id="username">
                                <FormLabel htmlFor="username">Username</FormLabel>
                                <Input                    
                                    id="username"
                                    placeholder="Enter a username"
                                    variant="filled"
                                    value={username}
                                    onChange={(event) => setUsername(event.target.value)}
                                />
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl id="email">
                                <FormLabel htmlFor="email">Email</FormLabel>
                                <Input                    
                                    id="email"
                                    type="email"
                                    variant="filled"
                                    placeholder="Enter an email"
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                            </FormControl>
                        </Box>
                        <Divider/>
                        <Box>            
                            <FormControl id="password" isRequired>
                                <FormLabel htmlFor="password">Password</FormLabel>                        
                                <InputGroup>
                                    <InputLeftElement>
                                        <Button mr={1} onClick={handleGenerateClick}><Icon as={RiRefreshLine} /></Button>
                                    </InputLeftElement>
                                    <Input
                                        type={showPassword ? 'text':'password'}
                                        id="password"
                                        variant="filled"
                                        placeholder="Type or generate a password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                    />
                                    {/*<InputLeftElement><IconButton icon={<Icon as={VscSettings} />}/></InputLeftElement>*/}
                                    
                                    <InputRightElement>
                                            <Button onClick={handleToggleShow}>
                                            {showPassword ? <Icon as={RiEyeOffFill}/> : <Icon as={RiEyeFill}/>}
                                            </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <br />
                            {!props?.item && <HStack justify='space-between'>
                                <Box>
                                    <FormLabel htmlFor="uppercase" mb="0">
                                        Uppercase
                                    </FormLabel>
                                    <Switch id="uppercase" size="lg" isChecked={uppercase} onChange={handleSetUppercase} colorScheme="brand" />
                                </Box>
                                <Box>
                                    <FormLabel htmlFor="lowercase" mb="0">
                                        Lowercase
                                    </FormLabel>
                                    <Switch id="lowercase" size="lg" isChecked={lowercase} onChange={handleSetLowercase} colorScheme="brand"/>
                                </Box>
                                <Box>
                                    <FormLabel htmlFor="numbers" mb="0">
                                        Numbers
                                    </FormLabel>
                                    <Switch id="numbers" size="lg" isChecked={numbers} onChange={handleSetNumbers} colorScheme="brand"/>
                                </Box>
                                <Box>
                                    <FormLabel htmlFor="symbols" mb="0">
                                        Symbols
                                    </FormLabel>
                                    <Switch id="symbols" size="lg" isChecked={symbols} onChange={handleSetSymbols} colorScheme="brand"/>
                                </Box>
                                <Box>
                                
                                </Box>
                            </HStack>}  
                            {!props?.item && <HStack my={2}>
                                <Text>Length: </Text>                               
                                <Slider aria-label="password-length" focusThumbOnChange={false} name="length" value={length} onChange={handleSliderChange} min={5} max={128} colorScheme="brand">
                                    <SliderTrack>
                                    <SliderFilledTrack />
                                    </SliderTrack>
                                    <SliderThumb fontSize="xs" color="black" boxSize="22px" children={length}/>
                                </Slider>
                                                           
                            </HStack>}         

                            <Button mt={3} rightIcon={<Icon as={hasCopied ? RiCheckFill : RiFileCopyFill}  />} onClick={onCopy} colorScheme={hasCopied ? "blue" : "gray"} size="md" justifySelf="flex-end" maxW="sm">
                                {hasCopied ? "Copied" : "Copy Password"}
                            </Button>                 
                        </Box>
                        <Divider/>
                        <Box w="full" >                        
                            {totp ? <TOTPContainer totp={totp} view={onOpen} delete={() => setTotp(undefined)} /> : <Button w="full" onClick={onOpen} leftIcon={<Icon as={RiTimerFill}/>}>Add TOTP</Button>}
                            <Modal isOpen={isOpen} onClose={onClose}>
                                <ModalOverlay />
                                <ModalContent>
                                <ModalHeader>{(props?.item?.totp ? "Edit " : "Create ") + "TOTP"}</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Box>
                                        <FormLabel htmlFor="totpSecret">Secret</FormLabel>                                        
                                        <InputGroup>
                                            <Input type={showTotpSecret ? "text" : "password"} id="totpSecret" placeholder="Enter TOTP Secret" value={totp?.secret} variant="filled" onChange={(event) => setTotpSecret(event.target.value)} />
                                            <InputRightElement>
                                                <Button variant="solid" onClick={() => setShowTotpSecret(!showTotpSecret)} >
                                                    {showTotpSecret ? <Icon as={RiEyeOffFill}/> : <Icon as={RiEyeFill}/>}
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                        
                                    </Box>
                                    {totp?.secret && <Box>
                                        <FormLabel htmlFor="totpBackups">Backup Codes</FormLabel>                                        
                                        <Textarea id="totpBackups" placeholder="Enter Backup Codes" value={totp?.backups} variant="filled" onChange={(event) => setTotpBackups(event.target.value)} />
                                    </Box>}
                                </ModalBody>

                                <ModalFooter>
                                <HStack spacing={4}>
                                    <Button rightIcon={<Icon as={RiDeleteBinFill}/>} variant="solid" colorScheme="red" onClick={() => {deleteTotp()}}>Remove</Button>
                                    <Button type="submit" mr={3} colorScheme="brand" onClick={onClose} rightIcon={<Icon as={RiCheckFill}/>} >Done</Button>    
                                </HStack>                                
                                </ModalFooter>
                                </ModalContent>
                            </Modal>
                        </Box>
                        
                        
                        <Divider/>
                        <Box>
                            <FormLabel htmlFor="url">Site</FormLabel>
                            <InputGroup>
                                <InputLeftAddon>https://</InputLeftAddon>
                                <Input
                                type="text"
                                id="url"
                                variant="filled"
                                placeholder="example.com"
                                value={site}
                                onChange={(event) => setSite(event.target.value)}
                                />                
                            </InputGroup>
                        </Box>
                        <Divider/>
                        {/*<Box>
                            <FormLabel htmlFor="folder">Folder</FormLabel>
                            <Select id="folder" defaultValue="social" value={folder} variant="filled" onChange={(event) => setFolder(event.target.value)}>
                                {folders.map((folder, index) => (
                                    <option key={index} value={folder.value}>{folder.text}</option>
                                ))}                    
                            </Select>
                        </Box>*/}
                        <Box>
                            <FormLabel htmlFor="url">Tags</FormLabel>
                            <InputGroup size="md">
                                <Input variant="filled" placeholder="Tag Name" value={tagInput} onChange={(event) => setTagInput(event.target.value)} />
                                <InputRightElement width="4.5rem">
                                    <Button di  bled={tagInput === ''} h="1.75rem" size="sm" onClick={(event) => {handleAddTag(tagInput)}}>
                                    Add
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                            <HStack spacing={2} mt={2}>
                                {tags.map((tag, index) => (
                                    <ItemTag key={index} index={index} value={tag} remove={handleRemoveTag} />
                                ))}
                            </HStack>
                        </Box>
                        <Divider/>
                        <Box>
                            <FormLabel htmlFor="desc">Notes</FormLabel>
                            <Textarea id="desc" value={notes} variant="filled" onChange={(event) => setNotes(event.target.value)} />
                        </Box>  
                        <Button leftIcon={<Icon as={RiPencilFill} />} variant="ghost" onClick={handleAddCustomInput}>
                        Add Custom Field
                        </Button>                                 
                        {customInputs.map((input, index) => (
                            <CustomInputBox value={input} update={updateCustomInput} key={index} index={index} remove={handleRemoveCustomInput} />
                        ))}                    
                    </Stack>
                </DrawerBody>

                <DrawerFooter>
                    <HStack spacing={4}>
                        {/*<Button colorScheme="gray" onClick={props.onClose}>
                            {props?.item? "Close" : "Discard"}
                        </Button>*/}
                        {props?.item && <Button colorScheme="red" onClick={() => setDialogIsOpen(true)} rightIcon={<Icon as={RiDeleteBinFill} />}>Delete</Button>}
                        <Button type="submit" colorScheme="brand" rightIcon={<Icon as={RiCheckFill}/>} >Save</Button>
                    </HStack>
                    
                </DrawerFooter>
            
        </DrawerContent>
        <AlertDialog
            isOpen={dialogIsOpen}
            leastDestructiveRef={cancelRef}
            onClose={onDrawerClose}
        >
            <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Item
                </AlertDialogHeader>

                <AlertDialogBody>
                Are you sure you want to permanently delete this item from your vault?.
                </AlertDialogBody>

                <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onDrawerClose}>
                    Cancel
                </Button>
                <Button colorScheme="red" onClick={handleDelete} ml={3}>
                    Delete
                </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        </form>
            
        
    )
}

export default NewCredentialForm;