
const argon2 = require('argon2-browser')
const bip39 = require('bip39')

// Utils to handle browser localStorage I/O
export const createSession = (loginResponse) => {
    localStorage.setItem('user', JSON.stringify(loginResponse))
}

export const getSession = () => {
    const data = localStorage.getItem('user')
    return data;
}

export const endSession = () => {
    localStorage.removeItem('user')
    window.location.reload();
}

export const getLocalAccounts = () => {
    const accounts = JSON.parse(localStorage.getItem('accounts')) || [];
    return accounts.reverse();
}
  
export function addLocalAccount(accountInfo) {
    let newAccount = accountInfo
    const accounts = getLocalAccounts();
    newAccount.saveDate = new Date()
    if (!accounts.some(account => account.email === accountInfo.email)) {
      //account doesn't already exist      
      accounts.push(newAccount)
    }
    localStorage.setItem('accounts', JSON.stringify(accounts));
}

export const deleteLocalAccount = (username) => {
    const accounts = getLocalAccounts();
    localStorage.setItem('accounts', JSON.stringify(accounts.filter((account) => account.username !== username)))   
}

//standard params for argon2
const argon2Options = {
    time: 3, 
    mem: 31250, 
    parallelism: 3, 
    hashLen: 32, 
    type: argon2.ArgonType.Argon2id
}




export const generatePassword = (len=20, uppercase=true, lowercase=true, numbers=true, symbols=true) => {
    var charSet = "";
    
    if(lowercase) {
        charSet += "abcdefghijklmnopqrstuvwxyz";
    }
    if(uppercase) {
        charSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    } 
    if(numbers) {
        charSet += "0123456789";
    } 
    if(symbols) {
        charSet += "!\"#$%&'()*+,-./:;<=>?@[\]^_`{|}~";
    } 
    var array = new Uint32Array(len);
    window.crypto.getRandomValues(array);

    let password = "";
    for (var i = 0; i < len; i++) {
        password += charSet[array[i]%charSet.length]
    }
    
    return password
}

//returns the second half of a SHA256 of a given password, i.e 128 bits (16 bytes)
async function SHA256_2(message) {
    const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string    
    return hashHex.slice(32);// return the last 16 bytes of the hex string
}

//returns an argon2 hash of the given password + email combination, using SHA256_2(password+email) as a salt
export const passwordHash = async (password, email) => {
    const salt = await SHA256_2(password + email)    
    return argon2.hash({ pass: password, salt: salt, ...argon2Options })    
}

export const passwordStrength = (password, other_inputs=[]) => {
    const zxcvbn = require('zxcvbn');
    return zxcvbn(password, other_inputs)
}

export const newMnemonic = () => {
    const mnemonic = bip39.generateMnemonic(256)
    return mnemonic;
}

export const mnemonicHash = async (mnemonic) => {
    const salt = await SHA256_2(mnemonic)
    return (await argon2.hash({ pass: mnemonic, salt: salt, ...argon2Options })).encoded
}

export const getMasterKey = async (mnemonic, email) => {
    const mnemonicSeed = bip39.mnemonicToSeedSync(mnemonic)
    const salt = await SHA256_2(email)
    return argon2.hash({ pass: mnemonicSeed, salt: salt, ...argon2Options})
}

export const getUserKey = async (email, password) => {
    const salt = await SHA256_2(email)
    return argon2.hash({ pass: password, salt: salt, ...argon2Options })
}

const getMessageEncoding = message => {
    let enc = new TextEncoder();
    return enc.encode(message);
}

export const buf2hex = buffer => { // buffer is an ArrayBuffer
    return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
}

export const hex2buf = hex => {
    return Uint8Array.from(Buffer.from(hex, 'hex'))
}

const importAESKey = rawKey => {
    return window.crypto.subtle.importKey(
        "raw",
        rawKey,
        "AES-GCM",
        true,
        ["encrypt", "decrypt"]
    );
}

const encryptMessage = async (key, data) => {
    let encoded = getMessageEncoding(data);
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const ctBuffer = await window.crypto.subtle.encrypt(
        {
            name: "AES-GCM",
            iv: iv
        },
        key,
        encoded
    );
    const ctArray = Array.from(new Uint8Array(ctBuffer)); // ciphertext as byte array
    const ctStr = ctArray.map((byte) => String.fromCharCode(byte)).join(""); // ciphertext as string
    const ctBase64 = btoa(ctStr); // encode ciphertext as base64
    const ivHex = Array.from(iv)
        .map((b) => ("00" + b.toString(16)).slice(-2))
        .join(""); // iv as hex string
    return ivHex + ctBase64;
}

const decryptMessage = async (key, ciphertext) => {
    const iv = ciphertext
        .slice(0, 24)
        .match(/.{2}/g)
        .map((byte) => parseInt(byte, 16)); // get iv from ciphertext
    const ctStr = atob(ciphertext.slice(24));
    // decode base64 ciphertext
    const ctUint8 = new Uint8Array(
        ctStr.match(/[\s\S]/g).map((ch) => ch.charCodeAt(0))
    );
    
    const ptBuffer = await window.crypto.subtle.decrypt(
        {
            name: "AES-GCM",
            iv: new Uint8Array(iv)
        },
        key,
        ctUint8
    );    
    const plaintext = new TextDecoder().decode(ptBuffer);
    return plaintext;
  }

/*
  export const getProtectedKey = async (userKey, masterKey, iv) => {
    const userAesKey = await importAESKey(userKey.hash);    
    const protectedKey = await encryptMessage(userAesKey, masterKey.hash)
    return protectedKey;
}
*/

export const getProtectedRecovery = async (userKey, recovery) => {
    let userAesKey = await importAESKey(userKey.hash);    
    const protectedRecovery = await encryptMessage(userAesKey, recovery)
    return protectedRecovery;
}

export const decryptProtectedRecovery = async (userKey, protectedRecovery) => {
    let userAesKey = await importAESKey(userKey.hash);    
    const recovery = await decryptMessage(userAesKey, protectedRecovery)
    return recovery;
}
  
export const decryptMasterKey = async (protectedKey, userKey) => {
    const userAesKey = await importAESKey(userKey.hash)
    let masterKey = await decryptMessage(userAesKey, protectedKey)    
    return masterKey;
}

export const encryptItem = async (masterKey, item) => {
    let AESKey = await importAESKey(masterKey.hash);
    const encryptedItem = await encryptMessage(AESKey, JSON.stringify(item))    
    return {
        data:encryptedItem,
        type: item.type
    }
}

export const decryptItem = async (masterKey, encryptedItem) => {    
    let AESKey = await importAESKey(masterKey.hash);
    const item = await decryptMessage(AESKey, encryptedItem.data)
    return JSON.parse(item)
}