import { Box, Switch } from "@chakra-ui/react"
import { Alert, AlertDescription, AlertIcon, AlertTitle } from "@chakra-ui/alert"
import { FormControl } from "@chakra-ui/form-control"

const ParanoidModeSwitcher = (props) => {
    return (
        <FormControl>            
            <Alert status="warning" mt={2} mb={2} variant="left-accent">
                <AlertIcon />
                <Box flex="1">
                    <AlertTitle>Paranoid Mode</AlertTitle>
                    <AlertDescription display="block">
                        <Box my="1">
                            <Switch colorScheme="brand" id="uppercase" size="lg" isChecked={!props.saveRecovery} onChange={() => props.setSaveRecovery(!props.saveRecovery)} mr="2"/>
                            {props.saveRecovery ? "Off" : "On"}
                        </Box>                        
                        Turning this on will require a master secret input on each new device. You will see instructions for setting this up on the next screen.
                    </AlertDescription>                    
                </Box>
            </Alert>                      
            
        </FormControl>
    )
}

export default ParanoidModeSwitcher;