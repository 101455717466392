import { useContext, useState, useEffect } from 'react'
import {  VStack, Text, Icon, Image, Divider, useColorMode, propNames, Badge, Box } from '@chakra-ui/react';
import { Link, Switch } from 'react-router-dom';
import { ColorModeSwitcher } from '../../ColorModeSwitcher';
import { RiPhoneLockFill, RiBankCardFill, RiLockFill,  RiGridFill,  RiSettings4Fill,RiAdminFill, RiRefreshLine } from 'react-icons/ri';
import logo from './../../assets/images/keyspace-stack-open.svg';
import MenuItem from './MenuItem';
import MenuButton from './MenuButton';
import { Button, ButtonGroup } from "@chakra-ui/react";
import { AuthContext } from './../../Auth/Context';

const Plan = (props) => {
    const context = useContext(AuthContext)
    const [plan, setPlan] = useState(undefined)
    useEffect(() => {
        const checkPlan = callback => {
            context.getData('/account/plan', {}, function(response) {                
                callback(response.data)
            })
        }

        checkPlan((response) => {
            setPlan(response.message)
        })
    }, [plan])

    return (
        <Box>
            {plan && <Badge cursor="pointer" onClick={() => props.onClick()} variant="outline" colorScheme={plan === 'PRO' ? 'brand' : 'gray'} fontSize="md">{plan}</Badge>}
        </Box>
        
    )
}

const Sidebar = (props) => {
    const { colorMode } = useColorMode();
    const context = useContext(AuthContext)
    return (
        <VStack w={[90]} boxShadow='2xl' minH='100vh' p={1} pt='10px' pb='70px' justify='space-between' mr='0'>
            <Link to="/"><Image onClick={() => props.setView('logins')} w="90px" src={logo} /></Link>
            <Plan onClick={() => props.setView('settings')}/>
            <Divider/>
            {/*<MenuItem to="/" text="All Items" icon={<Icon w={6} h={6} as={RiGridFill}/>}></MenuItem>*/}

            <MenuButton active={props.view.name === 'logins'} onClick={() => props.setView('logins')} text="Logins" icon={<Icon w={6} h={6} as={RiAdminFill}/>}></MenuButton>
            
            <MenuButton active={props.view.name === 'notes'} onClick={() => props.setView('notes')} text="Notes" icon={<Icon w={6} h={6} as={RiPhoneLockFill}/>}></MenuButton>
            
            <MenuButton active={props.view.name === 'cards'} onClick={() => props.setView('cards')} text="Cards" icon={<Icon w={6} h={6} as={RiBankCardFill}/>}></MenuButton>
            
            <Divider/>

            <MenuButton onClick={props.refresh} text="Sync" icon={<Icon w={6} h={6} as={RiRefreshLine}/>}></MenuButton>
            
            <MenuButton onClick={context.endSession} text="Lock" icon={<Icon w={6} h={6} as={RiLockFill}/>}></MenuButton>     

            <MenuButton onClick={() => props.setView('settings')} text="Settings" icon={<Icon w={6} h={6} as={RiSettings4Fill}/>}></MenuButton>       
            
            
                                        
        </VStack>
    )
}

export default Sidebar;
