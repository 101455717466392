import React, { useState, useContext, useEffect } from 'react'
import { 
    Box,
    Grid, 
    HStack, 
    Button,    
    Icon,     
    useDisclosure,    
    Flex,
    Spacer,
    Drawer,
    DrawerOverlay,
    IconButton,
    useToast,
    Stack,
    ButtonGroup,
    Fade,
    VStack
} from '@chakra-ui/react'
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
  } from "@chakra-ui/react"
import { Checkbox, CheckboxGroup } from "@chakra-ui/react"
import Sidebar from './Sidebar/Sidebar'
import DashboardSearch from './DashboardSearch'
import NewCredentialForm from './NewCredForm'
import NewNoteForm from './NewNoteForm'
import NewCardForm from './NewCardForm'
import { RiAddLine, RiFilterFill, RiPriceTag3Fill, RiArrowUpDownFill, RiDeleteBin4Fill } from 'react-icons/ri'
import { VaultContext } from './vault-context'
import { AuthContext } from '../Auth/Context'
import LoginsView from './LoginsView'
import CardsView from './CardsView'
import NotesView from './NotesView'
import vault from './testVault.json'
import {  encryptItem, decryptItem } from '../helpers/authUtils'
import Settings from './Settings/index'
import { Radio, RadioGroup } from "@chakra-ui/react"

const Vault = () => {    
    const context = useContext(VaultContext);
    const authContext = useContext(AuthContext);    
    const { isOpen: ItemDrawerIsOpen, onOpen: onItemDrawerOpen, onClose: onItemDrawerClose } = useDisclosure()
    const { isOpen: SettingsDrawerIsOpen, onOpen: onSettingsDrawerOpen, onClose: onSettingsDrawerClose } = useDisclosure()
    const [search, setSearch] = useState(null)
    const btnRef = React.useRef()
    const toast = useToast()
    //const [items, setItems] = useState(useContext(VaultContext).data.items.map((cred) => (
    //    <CredentialCard cred={cred} key={cred.key}/>
    // )))    
    const [loading, setLoading] = useState(true)
    const [vaultId, setVaultId] = useState(undefined)
    const [items, setItems] = useState([])
    const [itemInView, setItemInView] = useState(undefined)
    const [skeletons, setSkeletons] = useState(0)    
    
    const views = [
        {
            'name': 'Logins'            
        },
        {
            'name': 'Notes'
        },
        {
            'name': 'Cards'
        },
        {
            'name': 'Settings'
        }
    ]
    const [view, setView] = useState(views[0])

    const handleChangeView = name => {
        setView(views.find((view) => view.name.toLowerCase() === name))
    }

    const handleItemDrawerClose = () => {
        onItemDrawerClose()
        setItemInView(undefined)
    }

    const handleSettingsDrawerClose = () => {
        onSettingsDrawerClose()
    }

    
    
    const addItem = async (item) => {                
        setItems([...items, item])        
        const encryptedItem = await encryptItem(authContext.data.masterKey, item)
        const payload = {data: encryptedItem, ...{ id:item.id, vault_id: vaultId }}
        
        authContext.postData('/vaults/items/', payload);
        
        toast({
            title: "Success",
            description: "Item Added!",
            status: "success",
            duration: 2000,
            isClosable: true,
            position:"bottom-right"
        })
    }

    const deleteItem = async (item) => {         
        setItems(items.filter((i) => i.id !== item.id))                       
        
        const payload = {id: item.id}        
        
        authContext.deleteData('/vaults/items/', payload);
        
        toast({
            title: "Success",
            description: "Item Deleted!",
            status: "success",
            duration: 2000,
            isClosable: true,
            position:"bottom-right"
        })
    }

    const editItem = async (item) => {         
        setItems(items.map((i) => {
            if (i.id === item.id) return item
            return i
        }))                       
        
        const encryptedItem = await encryptItem(authContext.data.masterKey, item)
        const payload = {data: encryptedItem, ...{ id:item.id, vault_id: vaultId }}      
        
        authContext.putData('/vaults/items/', payload);
        
        toast({
            title: "Success",
            description: "Item Edited!",
            status: "success",
            duration: 2000,
            isClosable: true,
            position:"bottom-right"
        })
    }

    useEffect(() => {           
        const fetchVaults = (callback) => {
            authContext.getData('/vaults/', null, function(response) {
                //console.log('vaults', response)
                callback(response.data.data)
            })
        }
    
        const fetchVaultItems = (vault_id, callback) => {
            authContext.getData('/vaults/items', {
                vault_id: vault_id
            }, function(response) {
                //console.log('vault items', response)
                callback(response.data.data)
            })
        }

        const fetchItems = () => {
            fetchVaults((vaults) => {
                setVaultId(vaults[0].id)
                fetchVaultItems(vaults[0].id, async (items) => {                    
                    setSkeletons(items.length)
                    const decryptedItems = await Promise.all(items.map(async (item) => {                        
                        const decryptedItem = await decryptItem(authContext.data.masterKey, item.data);                        
                        return {...decryptedItem, id:item.id, type:item.type};
                    }));
                    setSkeletons(0)                    
                    setItems(decryptedItems)
                })

            })
                     
        }
        
        if (loading) {
            fetchItems()
            setLoading(false)            
        }
        
    }, [loading])

    
    
    const handleSearchChange = event => {
        setSearch(event.target.value)
    }

    const resetSearch = () => {
        setSearch('');
    }

    const viewItem = (item) => {
        setItemInView(item)
        onItemDrawerOpen()
    }

    const handleRefresh = () => {
        setLoading(true)
        setSkeletons(items.length)
    }
        
    return (
        <VaultContext.Provider value ={{
            data:{
                items:items            
            },
            addItem,
            deleteItem,
            editItem
            }}>
            <Box textAlign="center" fontSize="xl">
                <Grid minH="100vh" p={0}>                
                    
                    <HStack>
                        <Sidebar views={views} view={view} setView={handleChangeView} refresh={handleRefresh}/>
                        
                            <Box w='100%'>
                                <VStack h="100vh" p={2}> 
                                    <Flex w='100%' p={2} spacing={3} align='center'>
                                        <Box minW='80%' h='10'>
                                            <DashboardSearch setSearch={handleSearchChange} search={search} resetSearch={resetSearch} />          
                                        </Box>                
                                        <Spacer/>                
                                        <Box h='10' boxShadow='2xl'>
                                        <ButtonGroup size="md" isAttached variant="outline">                                            
                                            <Button onClick={onItemDrawerOpen} leftIcon={<Icon as={RiAddLine}/>} colorScheme='brand' variant="solid" aria-label="Add Item">New Item</Button>
                                        </ButtonGroup>
                                        </Box>                                
                                    </Flex>                                    
                                    
                                    <Box w='100%' h='full' p={2} overflowY='scroll' pt={0}>
                                        <Box pb={2}>
                                            <HStack py={3}>
                                            <Popover placement="right-end">
                                            <PopoverTrigger>
                                            <Button leftIcon={<Icon as={RiPriceTag3Fill} />} size="sm" variant="solid">
                                                Tags
                                            </Button>
                                            </PopoverTrigger>
                                            <PopoverContent>
                                                <PopoverArrow />
                                                <PopoverCloseButton />
                                                <PopoverBody><Checkbox defaultIsChecked>Social</Checkbox></PopoverBody>
                                                <PopoverBody><Checkbox defaultIsChecked>Cloud</Checkbox></PopoverBody>
                                                <PopoverBody><Checkbox defaultIsChecked>Infrastructure</Checkbox></PopoverBody>
                                                <PopoverBody><Checkbox defaultIsChecked>ACME Company Day Job</Checkbox></PopoverBody>
                                                <PopoverBody><Checkbox defaultIsChecked>Cure53</Checkbox></PopoverBody>
                                            </PopoverContent>
                                            </Popover>

                                            <Popover placement="right-end">
                                            <PopoverTrigger>                                            
                                            <Button rightIcon={<Icon as={RiArrowUpDownFill} />} size="sm" variant="solid">
                                                Sort
                                            </Button>
                                            </PopoverTrigger>
                                            <PopoverContent>
                                                <PopoverArrow />
                                                <PopoverCloseButton />
                                                <PopoverBody>
                                                    <Stack>
                                                    <RadioGroup defaultValue="1">
                                                    <Radio value="1">
                                                    A-Z
                                                    </Radio>
                                                    <Radio value="2">Unchecked</Radio>
                                                    <Radio value="3">Unchecked</Radio>
                                                </RadioGroup>
                                                </Stack>
                                                </PopoverBody>
                                            </PopoverContent>
                                            </Popover>

                                            <Popover placement="right-end">
                                            <PopoverTrigger>
                                            <Button rightIcon={<Icon as={RiDeleteBin4Fill} />} size="sm" variant="solid">
                                                Trash
                                            </Button>
                                            </PopoverTrigger>
                                            <PopoverContent>
                                                <PopoverArrow />
                                                <PopoverCloseButton />
                                                <PopoverHeader>Confirmation!</PopoverHeader>
                                                <PopoverBody>Are you sure you want to have that milkshake?</PopoverBody>
                                            </PopoverContent>
                                            </Popover>
                                            </HStack>
                                            <hr/>
                                        </Box>                                        
                                    <Fade in={view.name === 'Logins'} unmountOnExit>
                                        <LoginsView search={search} setSearch={setSearch} skeletons={skeletons} view={viewItem}/>                                        
                                    </Fade>
                                    <Fade in={view.name === 'Notes'} unmountOnExit>
                                        <NotesView search={search} setSearch={setSearch} skeletons={skeletons} view={viewItem}/>                                        
                                    </Fade>
                                    <Fade in={view.name === 'Cards'} unmountOnExit>
                                        <CardsView search={search} setSearch={setSearch} skeletons={skeletons} view={viewItem}/>                                        
                                    </Fade>
                                    <Fade in={view.name === 'Settings'} unmountOnExit>
                                        <Settings/>                                        
                                    </Fade>

                                    </Box>
                                </VStack>
                            </Box>
                    </HStack>
                    
                    
                </Grid>                
                
                <Drawer
                    isOpen={ItemDrawerIsOpen}
                    placement="right"
                    onClose={handleItemDrawerClose}
                    finalFocusRef={btnRef}
                    size='md'
                    closeOnOverlayClick={false}
                >
                    <DrawerOverlay>
                        {view.name === 'Logins' && <NewCredentialForm item={itemInView} isOpen={ItemDrawerIsOpen} onClose={handleItemDrawerClose} btnRef={btnRef} />}
                        {view.name === 'Notes' && <NewNoteForm item={itemInView} isOpen={ItemDrawerIsOpen} onClose={handleItemDrawerClose} btnRef={btnRef} />}
                        {view.name === 'Cards' && <NewCardForm item={itemInView} isOpen={ItemDrawerIsOpen} onClose={handleItemDrawerClose} btnRef={btnRef} />}
                    </DrawerOverlay>
                </Drawer>                
            </Box>
        </VaultContext.Provider>
    )
}

export default Vault
