import React, { useState } from 'react'
import { 
    Box, 
    Heading, 
    Input, 
    InputGroup, 
    InputLeftElement, 
    InputRightElement, 
    Icon, 
    FormControl, 
    Button, 
    Stack, 
    useToast, 
    VStack,
    HStack,
    Tag,
    TagLeftIcon,
    TagLabel, 
    useClipboard,
    ButtonGroup,
    Text,
    Grid,
    GridItem,
    SimpleGrid
} from '@chakra-ui/react'
import { RiShieldUserFill, RiKeyFill, RiEyeFill, RiEyeOffFill, RiFileCopyLine, RiMailFill, RiPriceTag3Fill, RiCheckFill, RiFileCopyFill, RiAccountBoxFill, RiLockPasswordFill, RiTimerFlashFill, RiEditFill } from 'react-icons/ri';
import { brands, matchBrand } from './../helpers/brandUtils'
import { getTOTP } from './../helpers/otpUtils'

const CredentialCard = (props) => {
    const brand = brands.find(brand => matchBrand(brand, props?.cred))
    const [showPassword, setShowPassword] = useState(false)
    const handleToggleShow = () => setShowPassword(!showPassword)    
    const toast = useToast()    

    const { hasCopied: hasCopiedUsername, onCopy: onCopyUsername } = useClipboard(props?.cred?.username || '')
    const { hasCopied: hasCopiedEmail, onCopy: onCopyEmail } = useClipboard(props?.cred?.email ||'' )
    const { hasCopied: hasCopiedPassword, onCopy: onCopyPassword } = useClipboard(props?.cred?.password || '')
    const { hasCopied: hasCopiedTOTP, onCopy: onCopyTOTP } = useClipboard(getTOTP(props?.cred?.totp?.secret).OTP || '')  

    

    const CopyButton = (props) => {
        const handleCopyUsername = () => {                   
            onCopyUsername();
        }
    
        const handleCopyEmail = () => {        
            onCopyEmail();
        }
    
        const handleCopyPassword = () => {        
            onCopyPassword();
        }    
    
        const handleCopyTOTP = () => {
            onCopyTOTP();
        }

        const types = [
            {
                name: 'username',
                icon: RiShieldUserFill,
                handleCopy: handleCopyUsername,
                copied: hasCopiedUsername,
                label: 'Copy Username' 
            },
            {
                name: 'email',
                icon: RiMailFill,
                handleCopy: handleCopyEmail,
                copied: hasCopiedEmail,
                label: 'Copy Email' 
            },
            {
                name: 'password',
                icon: RiLockPasswordFill,
                handleCopy: handleCopyPassword,
                copied: hasCopiedPassword,
                label: 'Copy Password' 
            },
            {
                name: 'totp',
                icon: RiTimerFlashFill,
                handleCopy: handleCopyTOTP,
                copied: hasCopiedTOTP,
                label: 'Copy TOTP' 
            }
        ]

        const variant = types.find((t) => t.name === props.type)
        const handleCopy = () => {
            variant.handleCopy();
            toast({
                title: "Copied",
                description: props.name + " " + props.type.toTitleCase(),
                status: "info",
                duration: 2000,
                isClosable: true,
                position:"bottom-right",
                variant:"subtle"
            }) 
        }
        return (
            <Box      
                //title="Copy"                       
                as="button" 
                transition="all 0.2s"
                onClick={handleCopy}    
                pt="1"
                border="2px"    
                borderColor={brand?.color || 'gray.600'}           
                borderTopRadius="0"
                bg={brand?.color || 'gray.600'}
                color="white"
                opacity={variant.copied ? "1" : "0.5"}
                _hover={{ 
                    opacity: "1",
                    boxShadow:"lg",
                 }}
                _active={{
                    border:"none"
                }}
                _focus={{
                    boxShadow:
                    "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
                }}
                
            >
                <VStack>
                    <Icon boxSize="1em" as={variant.copied ? RiCheckFill : variant.icon}/>
                    <HStack>
                    <Text fontSize={"xs"} as="label">{variant.label}</Text>
                    </HStack>
                </VStack>
            </Box>
        )
    }

    
    return (
        <Box w={'100%'} transition="all 0.2s" overflow='hidden' boxShadow='sm' _hover={{ boxShadow: 'lg' }}>
            <Box p={2} bg={brand?.color || 'gray.600'} >             
                <VStack color="white" align="left">                  
                    <HStack justify="space-between" as="button" onClick={() => props?.view(props?.cred)}>
                        <Heading textAlign="left"  size="md" as='h5' style={{cursor:"pointer"}}  >{<Icon mr={2} as={brand?.icon} />}{props?.cred?.name} </Heading>      
                        <Icon as={RiEditFill} />
                    </HStack>
                    
                    <Text textAlign="left" fontSize="xs" fontWeight="bold" _hover={{cursor: "pointer"}} >{props?.cred?.username || props?.cred?.email}</Text>                    
                    <HStack spacing={2}>
                        {props.cred.tags?.map((tag, index) => (
                            <Tag size={'sm'} key={index} variant="subtle" colorScheme="" onClick={() => {props.setSearch(tag)}} style={{cursor:"pointer"}}   _hover={{
                                background: "white",
                                color: brand?.color || 'gray.600',
                              }}>
                            <TagLeftIcon boxSize="12px" as={RiPriceTag3Fill} />
                            <TagLabel>{tag}</TagLabel>
                            </Tag>
                        ))}
                    </HStack>                                        
                </VStack>                
            </Box>            
            <Box>                
                <SimpleGrid minChildWidth="50px" spacing={1}>                        
                    {props?.cred?.username && <CopyButton type="username" name={props?.cred?.name} />}
                    {props?.cred?.email && <CopyButton type="email" name={props?.cred?.name} />}
                    {props?.cred?.password && <CopyButton type="password" name={props?.cred?.name} />}
                    {props?.cred?.totp && <CopyButton type="totp" name={props?.cred?.name} />}
                </SimpleGrid>                                    
            </Box>
            
        </Box>
    )
}

export default React.memo(CredentialCard);
