import React from 'react';
import {     
    Input, 
    InputGroup, 
    InputLeftElement,    
    Icon,    
    FormControl,
    Stack,
    FormLabel} from '@chakra-ui/react'
import { RiShieldUserFill, RiMailLine} from 'react-icons/ri';

const BasicInfo = (props) => {    
    
    return (                 
        <Stack spacing={4} w="full">                     
            <FormControl isRequired>
                <FormLabel>Your  Email Address </FormLabel>
                <InputGroup>
                    <InputLeftElement children={<Icon as={RiMailLine} />}/>
                    <Input variant="filled" type='email' placeholder='Email' aria-label='Email' ref={props?.ref} value={props.email} onChange={(event) => props?.setEmail(event.target.value)} autoFocus/>
                </InputGroup>
            </FormControl>

            <FormControl isRequired mt={5}>
                <FormLabel>Your Name</FormLabel>
                <InputGroup>
                    <InputLeftElement children={<Icon as={RiShieldUserFill} />}/>
                    <Input variant="filled" type='text' value={props.username} aria-label='Username' placeholder='Username' onChange={((event) => props?.setUsername(event.target.value))}/>
                </InputGroup>
            </FormControl>                                 
        </Stack>
       
    )
}

export default BasicInfo;