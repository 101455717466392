import React, { useState } from 'react'
import { 
    Box,     
    HStack,    
    SkeletonCircle,
    SkeletonText
} from '@chakra-ui/react'



const SkeletonCard = (props) => {
   

    return (
        <Box w={'100%'} minH="144px" transition="all 0.2s"  rounded='2px'  overflow='hidden' boxShadow='sm' _hover={{ boxShadow: 'lg' }}>
            <Box p={2}>                             
                <SkeletonCircle size="8" />   
                <SkeletonText mt={2} noOfLines={2} spacing="4" />                  
            </Box> 
        </Box>
    )
}

export default SkeletonCard;
