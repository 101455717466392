import { Input, InputGroup, InputLeftElement, Icon, InputRightElement, Button } from '@chakra-ui/react'
import { RiSearchLine, RiCloseLine } from 'react-icons/ri'

const DashboardSearch = (props) => {
    return (
        <InputGroup >
            <InputLeftElement children={<Icon as={RiSearchLine} w={6} h={6} />}/>
            <Input type='search' placeholder='Search' aria-label='Search' variant="filled" size='md' value={props.search} onChange={props.setSearch}  focusBorderColor="#61EFCE"/>
            <InputRightElement>
            <Button variant="ghost" onClick={props.resetSearch} ><Icon as={RiCloseLine} /></Button>
            </InputRightElement>
        </InputGroup>       
    )
}

export default DashboardSearch