import * as SimpleIcons from "react-icons/si"
const simpleIconsApi = require('simple-icons');


export const brands = [
    {
        name:'youtube',
        icon:SimpleIcons.SiYoutube,
        color:'#'+simpleIconsApi.get('Youtube').hex
    },
    {
        name:'google',
        icon:SimpleIcons.SiGoogle,
        color:'#'+simpleIconsApi.get('Google').hex
    },
    {
        name:'spotify',
        icon:SimpleIcons.SiSpotify,
        color:'#'+simpleIconsApi.get('Spotify').hex
    },
    {
        name:'amazon',
        icon:SimpleIcons.SiAmazon,
        color:'#'+simpleIconsApi.get('Amazon').hex
    },
    {
        name:'linkedin',
        icon:SimpleIcons.SiLinkedin,
        color:'#'+simpleIconsApi.get('Linkedin').hex
    },
    {
        name:'facebook',
        icon:SimpleIcons.SiFacebook,
        color:'#'+simpleIconsApi.get('Facebook').hex
    },
    {
        name:'gitlab',
        icon:SimpleIcons.SiGitlab,
        color:'#'+simpleIconsApi.get('Gitlab').hex
    },
    {
        name:'netflix',
        icon:SimpleIcons.SiNetflix,
        color:'#'+simpleIconsApi.get('Netflix').hex
    }
]

export const matchBrand = (brand, cred) => {
    //return Object.values(props?.cred).some(v => brand.name.includes(v))
    //return brand.name.includes(cred?.name.toLowerCase())
    return cred?.name.toLowerCase().includes(brand.name)
    
    /* WIP

    return Object.values(cred).forEach((value) => {
        try {
            value?.includes(brand.name)
        } catch(err) {
            return false
        }
    })
    */
}