import React from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react"
import {
    Box, VStack
} from '@chakra-ui/react';
import { ColorModeSwitcher } from '../../ColorModeSwitcher';
import AccountInfo from './AccountInfo'
import Plans from './Plans'
import Security from './Security'
import { Code } from "@chakra-ui/react"
const Settings = (props) => {
    return (
      <Box mx={2}>
        <Tabs isFitted isLazy colorScheme="brand">
          <TabList>
            <Tab>General</Tab>            
            <Tab>Security</Tab>
            <Tab>Help</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <VStack spacing={4}>
                <Box>
                  <p>Theme:</p>
                  <ColorModeSwitcher/>
                </Box>
                <Box>
                  <AccountInfo/>
                  <Plans />
                </Box>
              </VStack>
            </TabPanel>
            
            <TabPanel>
              <Security />

            </TabPanel>
            <TabPanel>
            </TabPanel>
            <TabPanel>
                Email us at info@keyspace.cloud
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      
    )
}

export default Settings;