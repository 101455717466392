import React, { useState } from 'react'
import { 
    Box,    
    Grid, 
    VStack, 
    Image, 
    Text,
    Button,
    useDisclosure,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Icon,
    IconButton
} from '@chakra-ui/react'
import { RiLoginBoxFill, RiCloseLine, RiShieldUserFill } from 'react-icons/ri'
import LoginForm from './LoginForm'
import SignUpForm from './SignUp/index'
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import logo from './../assets/images/keyspace-stack-open.svg';
import './../assets/css/bg_anim.css'
import { getLocalAccounts, deleteLocalAccount } from '../helpers/authUtils'


const Auth = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [accounts, setAccounts] = useState(getLocalAccounts())

    const handleDeleteLocalAccount = email => {
        deleteLocalAccount(email)
        setAccounts(getLocalAccounts())
    }

    const refreshAccounts = () => setAccounts(getLocalAccounts())

    return (
        
        <Box textAlign="center" fontSize="xl">            
          <Grid minH="100vh" p={3}>
            <ColorModeSwitcher justifySelf="flex-end" />
            <VStack spacing={8}>
                <VStack>
                    <Image w='200px' src={logo} />
                    <Text fontSize="40px" fontFamily="Major Mono Display" textShadow="md">keyspace</Text>
                </VStack>
                <Box w='500px' p={10} rounded="0px">
                    {accounts?.length > 0 && <Accordion allowToggle defaultIndex={[0]} pb={3}>
                        {accounts.map((account) => (
                            <AccordionItem key={account.email} _expanded>
                            <h2>
                            <AccordionButton _expanded={{ fontWeight:"bold" }}>
                                <Box flex="1" textAlign="center" >
                                    <Icon as={RiShieldUserFill} mr={2} />{account.username}
                                    <IconButton title="Forget account on this device" variant="ghost" colorScheme="red" ml={2} icon={<Icon as={RiCloseLine} />} onClick={() => handleDeleteLocalAccount(account.username)} />
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} pt={4} >
                            <LoginForm setUserSession={props.setUserSession} account={account} />
                            </AccordionPanel>
                        </AccordionItem>
                        ))}
                        <AccordionItem key={"newAccount"}>
                            <h2>
                            <AccordionButton _expanded={{ fontWeight: "bold" }}>
                                <Box flex="1" textAlign="center" pt={2} pb={2}>
                                <Icon as={RiLoginBoxFill} mr={2} />Not listed here?
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} pt={4}>
                            <LoginForm setUserSession={props.setUserSession}/>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>}
                    {accounts.length === 0 && <LoginForm setUserSession={props.setUserSession}/>}
                    <Button colorScheme='cyan' isFullWidth mt={8} onClick={onOpen} variant="outline" >Create a vault</Button>
                    <SignUpForm isOpen={isOpen} onOpen={onOpen} onClose={onClose} refreshAccounts={refreshAccounts}/>                    
                </Box>
            </VStack>
          </Grid>
        </Box>
        
    )
}

export default Auth