import {
    Center,
    Icon,
    SimpleGrid,
    VStack,
    Text
} from '@chakra-ui/react'
import { VaultContext } from './vault-context'
import { useContext, useState, useEffect, useMemo } from 'react'
import CurrencyCard from './CurrencyCard'
import SkeletonCard from './SkeletonCard'
import { RiBankCardFill } from 'react-icons/ri'

const Placeholder = () => {
    return (
        <Center w="full" h="xl" textAlign="center" verticalAlign="middle">
            <VStack>
                <Icon w={24} h={24} as={RiBankCardFill} color="gray" opacity="0.5"/>
                <Text color="gray" opacity="0.5">No Cards yet</Text>
            </VStack>
        </Center>
    )
}

const CardsView = (props) => {
    const context = useContext(VaultContext)
    const [items, setItems] = useState(context.data.items.filter((item) => item.itemType === 3))        
    
    const filterItems = () => {
        if (!props.search) {
            return context.data.items.filter((item) => item.itemType === 3);
        }
        const query = props.search.toLowerCase();
        return context.data.items.filter((item) => item.itemType === 3).filter((item) => {
            return item.name.toLowerCase().includes(query) || 
            item.cardOwner.toLowerCase().includes(query) || 
            item.cardNumber.toLowerCase().includes(query) || 
            item.cardExpiry.toLowerCase().includes(query) || 
            item.cardCvv.toLowerCase().includes(query) || 
            item.cardType?.toLowerCase().includes(query) || 
            item.notes?.toLowerCase().includes(query) || 
            (item.tags?.forEach(tag => {
                tag.toLowerCase().includes(query)                
            }))
        })
    }
    const filteredItems = useMemo(() => filterItems(), [props.search, context.data.items])

        
    useEffect(() => {
        setItems(filteredItems)
    }, [props.search, context.data.items])

    return (
        <SimpleGrid minChildWidth={'18rem'} spacing="20px">            
            {/*new Array(12).fill('').map((index) => <SkeletonCard key={index}/>)*/}
            {items?.length === 0 && <Placeholder/>}    
            {items.map((item, index) => <CurrencyCard key={index} item={item} view={props?.view}/>)}  
        </SimpleGrid>
    )
}

export default CardsView;