import React, { useState, useContext, useRef, useEffect } from 'react'
import {
    Input,    
    Stack,
    FormLabel,    
    Textarea,
    Box,
    Icon,
    Button,
    HStack,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,    
    DrawerContent,
    DrawerCloseButton,
    FormControl,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Tabs, 
    TabList, 
    TabPanels, 
    Tab, 
    TabPanel,
    SimpleGrid,
    Text
} from '@chakra-ui/react'
import { RiDeleteBinFill, RiCheckFill, RiStickyNoteFill } from 'react-icons/ri'
import { VaultContext } from './vault-context'
import { v4 as uuidv4 } from 'uuid';
import { colors } from '../helpers/noteUtils'
import  { markdown } from './../helpers/markdown'


const NewNoteForm = (props) => {
    const context = useContext(VaultContext)    
    const [dialogIsOpen, setDialogIsOpen] = React.useState(false)
    const onDrawerClose = () => setDialogIsOpen(false)
    const cancelRef = React.useRef()  
    const firstField = React.useRef()
    const [name, setName] = useState(props?.item?.name || '')
    const [note, setNote] = useState(props?.item?.note || '') 
    
    const [color, setColor] = useState(props?.item?.color || colors[0])

    const submit = async (event) => {
        event.preventDefault();
        
        const newItem = {                   
            itemType: 2,
            name: name,            
            note: note,
            color: color            
        }

        if (!props.item) {
            newItem.id = uuidv4() 
            context.addItem(newItem)            
        }

        else {
            newItem.id = props.item.id
            context.editItem(newItem)
        }
        
        props.onClose();
    }

    const handleDelete = (event) => {
        context.deleteItem(props.item)
        props.onClose();
    }

    const ColorButton = props => {
        const [selected, setSelected] = useState(false)
        
        useEffect(() => {
            if (props.selectedColor.bg === props.color.bg) {
                setSelected(true)
                return true
            }
            setSelected(false)
        }, [props.selectedColor, props.color])
        return (
            <Box as="button" w="2rem" h="2rem" rounded="50%" bg={props.color.bg} onClick={() => setColor(props.color)}>
                {selected && <Icon color={props.color.text} as={RiCheckFill}/>}
            </Box>
        )
    }

    

    return (
        <form onSubmit={submit}>
        <DrawerContent>
            
                <DrawerCloseButton color={color.text} />
                <DrawerHeader bg={color.bg} color={color.text} transition="all 0.2s">{props.item && <Icon mr={2} as={RiStickyNoteFill} />}{props?.item? props.item.name : "Create a new Note"}</DrawerHeader>

                <DrawerBody>
                    <Stack spacing="10px">
                        <Box>                            
                            <FormControl id="name" isRequired>
                                <FormLabel htmlFor="name">Name</FormLabel>
                                <Input
                                    ref={firstField}
                                    id="name"
                                    variant="filled"
                                    placeholder="Enter Item Name"
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}
                                />
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl id="note">
                                <FormLabel htmlFor="note">Note</FormLabel>
                                <Tabs colorScheme={color.bg} >
                                    <TabList>
                                        <Tab>Edit</Tab>
                                        <Tab>Preview</Tab>                                        
                                    </TabList>

                                    <TabPanels>
                                        <TabPanel p={2}>
                                            <Textarea                    
                                                id="note"
                                                placeholder=""
                                                variant="filled"
                                                resize="vertical"
                                                isFullWidth
                                                value={note}
                                                rows="20"
                                                onChange={(event) => setNote(event.target.value)}
                                            />
                                        </TabPanel>
                                        <TabPanel bg={color.bg} color={color.text} p={2} rounded="10px" mt="2">
                                            <Box>
                                                <div textAlign="left" dangerouslySetInnerHTML={{ __html: markdown(note) }}></div>
                                            </Box>
                                        </TabPanel>
                                        
                                    </TabPanels>
                                </Tabs>
                                
                            </FormControl>
                        </Box> 
                        <Box>
                            <FormLabel htmlFor="note">Color</FormLabel>
                            <SimpleGrid minChildWidth="1rem" spacing="10px">
                                {colors.map((col) => <ColorButton color={col} selectedColor={color} />)}
                            </SimpleGrid>
                        </Box>
                    </Stack>
                </DrawerBody>

                <DrawerFooter>
                    <HStack spacing={4}>
                        {/*<Button colorScheme="gray" onClick={props.onClose}>
                            {props?.item? "Close" : "Discard"}
                        </Button>*/}
                        {props?.item && <Button colorScheme="red" onClick={() => setDialogIsOpen(true)} rightIcon={<Icon as={RiDeleteBinFill} />}>Delete</Button>}
                        <Button type="submit" colorScheme="brand" rightIcon={<Icon as={RiCheckFill}/>} >Save</Button>
                    </HStack>
                    
                </DrawerFooter>
            
        </DrawerContent>
        <AlertDialog
            isOpen={dialogIsOpen}
            leastDestructiveRef={cancelRef}
            onClose={onDrawerClose}
        >
            <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Item
                </AlertDialogHeader>

                <AlertDialogBody>
                Are you sure you want to permanently delete this item from your vault?.
                </AlertDialogBody>

                <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onDrawerClose}>
                    Cancel
                </Button>
                <Button colorScheme="red" onClick={handleDelete} ml={3}>
                    Delete
                </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        </form>
            
        
    )
}

export default NewNoteForm;