import React, { useState, useRef, useEffect } from 'react';
import { 
    Box,    
    Icon,
    FormControl,
    Input,        
    Heading,
    SimpleGrid,
    Text,
    InputGroup,
    InputLeftElement,
    InputRightElement
} from '@chakra-ui/react';
import { RiCheckFill} from 'react-icons/ri';

const WordInput = (props) => {    
    const [value, setValue] = useState('')
    const handleChange = (event) => {
        setValue(event.target.value)
        props.updateInput(props.index, event.target.value) 
        if (event.target.value === props.correctInput) { props.updateFocus(event, props.index); }
    }
    return (
        <InputGroup>
            <InputLeftElement color="gray.500" children={(props.index + 1)} />
            <Input variant="flushed" value={value} onChange={handleChange} disabled={value === props.correctInput} borderColor={value === props.correctInput ? "springgreen": "inherit"} type={value === props.correctInput ? "password" : "text"} />
            {value === props.correctInput && <InputRightElement children={<Icon as={RiCheckFill} color="green.500" />} />}
        </InputGroup>
    )
}


const Verification = (props) => {        
    const correctInputs = props.mnemonic.split(' ')
    const [inputs, setInputs] = useState(new Array(24).fill(''))
    const refs = new Array(24).fill(useRef(null))
    
    const updateInput = (index, value) => {
        setInputs(inputs.map((input, i) => {
            if (index === i) return value;
            return input;
        }))        
    }    

    const handleUpdateFocus = (event, index) => {
        console.log('focus -> ', (index+1))
        if (index < refs.length-1) {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            form.elements[index + 1].focus();
        }
        
    }

    useEffect(() => {
        props.setTypedMnemonic(inputs.join(' '))
    }, [inputs, props])

    return (        
        <FormControl isRequired={props.active} >
            <Box textAlign="center">
                <Heading size="sm">Recovery Verification</Heading>
                <Text>Please re-enter your recovery phrase.</Text>
                <SimpleGrid mt={3} mb={3} minChildWidth="100px" spacing="10px">
                    {inputs.map((input, index) => <WordInput ref={refs[index]} updateFocus={handleUpdateFocus} correctInput={correctInputs[index]} key={index} updateInput={updateInput} index={index}/>)}
                </SimpleGrid>
            </Box>
        </FormControl>        
    )
}

export default Verification;