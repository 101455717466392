import React, { useState, useContext, useRef, useEffect } from 'react'
import {
    Input,    
    Stack,
    FormLabel,    
    Textarea,
    Box,
    Icon,
    Button,
    HStack,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,    
    DrawerContent,
    DrawerCloseButton,
    FormControl,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,    
    NumberInput,
    NumberInputField,
    Text,
    Select,
} from '@chakra-ui/react'
import { RiDeleteBinFill, RiCheckFill, RiStickyNoteFill } from 'react-icons/ri'
import { VaultContext } from './vault-context'
import { v4 as uuidv4 } from 'uuid';
import { detectCardType } from '../helpers/creditCardUtils';


const NewCardForm = (props) => {
    const context = useContext(VaultContext)    
    const [dialogIsOpen, setDialogIsOpen] = React.useState(false)
    const onDrawerClose = () => setDialogIsOpen(false)
    const cancelRef = React.useRef()  
    const firstField = React.useRef()
    const [name, setName] = useState(props?.item?.name || '')
    const [cardOwner, setCardOwner] = useState(props?.item?.cardOwner || '')
    const [cardNumber, setCardNumber] = useState(props?.item?.cardNumber || '')
    const [cardExpiry, setCardExiry] = useState(props?.item?.cardExpiry || '')
    const [cardCvv, setCardCvv] =useState(props?.item?.cardCvv || '')    
    const [cardType, setCardType] = useState(detectCardType(props?.item?.cardNumber.replace(/\s/g, '')))
    const [notes, setNotes] = useState(props?.item?.notes || '')

    useEffect(() => {
        setCardType(detectCardType(cardNumber.replace(/\s/g, '')))        
    }, [cardNumber])

    const handleCardNumberChange = (event) => {
        alert('event')
        //setCardNumber(event.target.value.replace(/\D/g, ""))
        setCardNumber('test')
    }

    const submit = async (event) => {
        event.preventDefault();
        
        const newItem = {                   
            itemType: 3,
            name: name,            
            cardOwner: cardOwner,
            cardNumber: cardNumber,
            cardExpiry: cardExpiry,
            cardCvv: cardCvv,
            cardType: cardType.name,
            notes: notes
        }        

        if (!props.item) {
            newItem.id = uuidv4() 
            context.addItem(newItem)            
        }

        else {
            newItem.id = props.item.id
            context.editItem(newItem)
        }
        
        props.onClose();
    }

    const handleDelete = (event) => {
        context.deleteItem(props.item)
        props.onClose();
    }

    const monthOptions = () => {
        return (
            Array.from(Array(12).keys()).map((n) => <option style={{ fontFamily: "Roboto"}} value={(parseInt(n, 10) + 101).toString().substr(1)}>{(parseInt(n, 10) + 101).toString().substr(1)}</option>)
        )        
    }

    const handleMonthChange = (event) => {
        setCardExiry(event.target.value + '/' + cardExpiry.split('/')[1])
    }

    const yearOptions = () => {
        return (
            Array.from(Array(20).keys()).map((n) => <option value={n+2020}>{n+2020}</option>)
        )        
    }

    const handleYearChange = (event) => {
        setCardExiry(cardExpiry.split('/')[0] + '/' + event.target.value)
    }

    const format = (val) => val.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()
    const parse = (val) => val.replace(/\s/g, '');

    return (
        <form onSubmit={submit}>
        <DrawerContent>
            
                <DrawerCloseButton color={props?.item ? "white" : "inherit"} />
                <DrawerHeader bg={props?.item ? cardType.color : 'inherit'} color={props?.item ? "white" : "inherit"} >
                    {props.item && <Icon mr={2} as={cardType.icon} />}
                    {props?.item? props.item.name : "Create a new Card"}
                </DrawerHeader>

                <DrawerBody>
                    <Stack spacing="10px">
                        <Box>                            
                            <FormControl isRequired>
                                <FormLabel htmlFor="name">Name</FormLabel>
                                <Input
                                    ref={firstField}
                                    id="name"
                                    variant="filled"
                                    placeholder="Enter Item Name"
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}                                    
                                />
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl isRequired>
                                <FormLabel htmlFor="owner">Cardholder Name</FormLabel>
                                <Input                    
                                    id="owner"
                                    placeholder="eg: John Smith"
                                    variant="filled"
                                    value={cardOwner}
                                    onChange={(event) => setCardOwner(event.target.value)}
                                />
                            </FormControl>
                        </Box> 
                        <Box>
                            <FormControl isRequired>
                                <FormLabel htmlFor="cardnumber">Card Number</FormLabel>                                
                                <NumberInput
                                    inputMode="numeric"
                                    id="cardnumber"
                                    placeholder="XXXX XXXX XXXX XXXX"
                                    onChange={(valueString) => setCardNumber(parse(valueString))}
                                    value={cardNumber}
                                    variant="filled"
                                >
                                <NumberInputField />                                
                                </NumberInput>   
                            </FormControl>                            
                        </Box> 
                        <HStack justify="space-between">
                            <Box>
                                <FormControl isRequired>
                                    <FormLabel htmlFor="owner">Card Expiry</FormLabel>
                                    <HStack>
                                    <Select variant="filled" placeholder="MM" value={cardExpiry.split('/')[0]} onChange={handleMonthChange}>
                                        {monthOptions()}
                                    </Select>
                                    <Text>/</Text>
                                    <Select variant="filled" placeholder="YY" value={cardExpiry.split('/')[1]} onChange={handleYearChange}>
                                        {yearOptions()}
                                    </Select>
                                    </HStack>                                    
                                </FormControl>
                            </Box> 
                            <Box>
                                <FormControl isRequired>
                                    <FormLabel htmlFor="owner">CVV</FormLabel>
                                    <Input                    
                                        id="cvv"
                                        placeholder="XXX"
                                        variant="filled"
                                        value={cardCvv}
                                        onChange={(event) => setCardCvv(event.target.value)}
                                        w="24"
                                    />
                                </FormControl>
                            </Box> 
                            
                        </HStack>
                        <Box>
                            <FormLabel htmlFor="desc">Notes</FormLabel>
                            <Textarea id="desc" value={notes} variant="filled" onChange={(event) => setNotes(event.target.value)} />
                        </Box>
                    </Stack>
                </DrawerBody>

                <DrawerFooter>
                    <HStack spacing={4}>
                        {/*<Button colorScheme="gray" onClick={props.onClose}>
                            {props?.item? "Close" : "Discard"}
                        </Button>*/}
                        {props?.item && <Button colorScheme="red" onClick={() => setDialogIsOpen(true)} rightIcon={<Icon as={RiDeleteBinFill} />}>Delete</Button>}
                        <Button type="submit" colorScheme="brand" rightIcon={<Icon as={RiCheckFill}/>} >Save</Button>
                    </HStack>
                    
                </DrawerFooter>
            
        </DrawerContent>
        <AlertDialog
            isOpen={dialogIsOpen}
            leastDestructiveRef={cancelRef}
            onClose={onDrawerClose}
        >
            <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Item
                </AlertDialogHeader>

                <AlertDialogBody>
                Are you sure you want to permanently delete this item from your vault?.
                </AlertDialogBody>

                <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onDrawerClose}>
                    Cancel
                </Button>
                <Button colorScheme="red" onClick={handleDelete} ml={3}>
                    Delete
                </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        </form>
            
        
    )
}

export default NewCardForm;