import React from 'react'
import { 
    Box, 
    Button,
    HStack,
    Icon,
    Image,
    Text,
    VStack, 
    useClipboard,
    useToast,    
    Tooltip,
    IconButton
} from '@chakra-ui/react';
import { detectCardType } from '../helpers/creditCardUtils';
import chip from '../assets/images/credit-card-chip.svg';
import { RiEditFill } from 'react-icons/ri';

const CurrencyCard = (props) => {
    const cardType = detectCardType(props?.item.cardNumber.replace(/\s/g, ''))
    const toast = useToast()
    const { hasCopied: hasCopiedNumber, onCopy: onCopyNumber } = useClipboard(props?.item.cardNumber || '')
    const { hasCopied: hasCopiedOwner, onCopy: onCopyOwner } = useClipboard(props?.item.cardOwner || '')
    const { hasCopied: hasCopiedExpiry, onCopy: onCopyExpiry } = useClipboard(props?.item.cardExpiry || '')
    const { hasCopied: hasCopiedCvv, onCopy: onCopyCvv } = useClipboard(props?.item.cardCvv || '')
    
    const copyToast = (message) => {        
        toast({
            title: "Copied",
            description: message,
            status: "info",
            duration: 2000,
            isClosable: true,
            position:"bottom-right",
            variant:"subtle"
        })        
    }
    const handleCopyNumber = event => {        
        onCopyNumber();
        copyToast(props?.item.name + ' card number')
    }

    const handleCopyOwner = event => {       
        onCopyOwner();
        copyToast(props?.item.name + ' card owner')
    }

    const handleCopyExpiry = event => {       
        onCopyExpiry();
        copyToast(props?.item.name + ' card expiry')
    }
    
    const handleCopyCvv = event => {       
        onCopyCvv();
        copyToast(props?.item.name + ' card CVV')
    }

    const format = cardNumber => {
        if (cardType.name === 'american express') {      
            //amex uses a 465 spacing pattern, so we need spaces at indices 4 and 11
            let output = [cardNumber.slice(0, 4), ' ', cardNumber.slice(4)].join('')            
            output = [output.slice(0, 12), ' ', output.slice(11)].join('')
            return output;
        }
        //4444 spacing for others
        return cardNumber.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()
    }
    

    return (
        <Box 
            minW="18rem"  
            maxW="md"
            h="11rem" 
            transition="all 0.2s" 
            rounded='5px' 
            py="5"
            //onClick={() => props?.view(props?.cred)} 
            //style={{ cursor: "pointer"}} 
            overflow='hidden' 
            boxShadow='sm' 
            _hover={{ boxShadow: 'lg' }} 
            bg={cardType?.color || "gray.800"} 
            color="white" 
            textAlign="left"
            verticalAlign="center"
        >
            <HStack justify="space-between" h="1" px="3" m="1">
                <Image src={chip} w="35px" h="25px"/>  
                <HStack>
                    <Icon as={cardType?.icon}/>
                    <Text px="1" isTruncated fontStyle="italic" fontWeight="semibold" fontSize="sm">{props?.item.name || cardType?.name}</Text>                    
                </HStack>                
            </HStack>            
            <Box p={2}>               
                <VStack>
                    <HStack pt="2" w="full" justify='center' as="button">   
                        <Tooltip hasArrow label="Copy Number" bg="black" color="white" placement="top">
                            <Button colorScheme={cardType?.color || "gray.800"} fontSize="xl" onClick={handleCopyNumber} variant="ghost">{format(props?.item.cardNumber)}</Button>
                        </Tooltip>                                             
                   </HStack>
                   <Box w='full' >
                        <HStack w="full" justify="space-between">
                            <Tooltip hasArrow label="Copy Cardholder Name" bg="black" color="white" placement="bottom">
                                <Button colorScheme={cardType?.color || "gray.800"} onClick={handleCopyOwner} variant="ghost" textAlign="left">
                                    <VStack spacing="0">
                                        <Text>{props?.item.cardOwner}</Text>
                                        <Text fontWeight="light" fontSize="xs" textColor="GrayText">Card holder</Text>
                                    </VStack>
                                </Button>
                            </Tooltip>
                            
                            <Tooltip hasArrow label="Copy Expiry Date" bg="black" color="white" placement="bottom">
                                <Button colorScheme={cardType?.color || "gray.800"} onClick={handleCopyExpiry} variant="ghost" textAlign="left">
                                    <VStack spacing="0">
                                        <Text>{props?.item.cardExpiry}</Text>
                                        <Text fontWeight="light" fontSize="xs" textColor="GrayText">Expiry</Text>
                                    </VStack>
                                </Button>
                            </Tooltip>
                            
                            <Tooltip hasArrow label="Copy Security Code" bg="black" color="white" placement="bottom">
                                <Button colorScheme={cardType?.color || "gray.800"} onClick={handleCopyCvv} variant="ghost" textAlign="left">
                                    <VStack spacing="0">
                                        <Text>XXX</Text>
                                        <Text fontWeight="light" fontSize="xs" textColor="GrayText">CVV</Text>
                                    </VStack>
                                </Button>
                            </Tooltip>
                        </HStack>
                   </Box>
                   
               </VStack>
            </Box>
            <HStack justify="right">
                <Tooltip hasArrow label="Edit Card" bg="black" color="white" placement="bottom">
                    <IconButton variant="ghost" icon={<Icon as={RiEditFill}/>} onClick={() => props?.view(props?.item)}/>
                </Tooltip>
            </HStack>
        </Box>
    )
}

export default CurrencyCard