import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Heading, Text, VStack, Badge, Spinner, HStack } from '@chakra-ui/react';
import { AuthContext } from './../../Auth/Context'
import logo from './../../assets/images/keyspace-stack-transparent.svg';
import { getLocalAccounts } from './../../helpers/authUtils'

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

const UpgradeForm = (props) => {    
    const authContext = useContext(AuthContext);    
    const [loading, setLoading] = useState(false)
    
    const startPaymentRequest = callback => {
        authContext.postData('/account/payments/start', {}, function(response) {            
            callback(response.data)
        })
    }

    const verifyPayment = (data, callback) => {        
        authContext.postData('/account/payments/verify', data, function(response) {
            callback(response.data)
        })
    }
    
    async function displayRazorpay() {
        setLoading(true)
        let orderDetails;
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        // creating a new order
        startPaymentRequest((response) => {
            //alert(JSON.stringify(response))
            if (response?.status === 'success'){
                setLoading(false)
                
                orderDetails = response.data
                const options = {
                    key: process.env.REACT_APP_RAZORPAY_KEY_ID,
                    amount: orderDetails.order_amount.toString(),
                    currency: orderDetails.order_currency,
                    name: "Keyspace Vault",
                    description: "Upgrade to Pro Account",
                    image: { logo },
                    order_id: orderDetails.order_id,
                    handler: async function (response) {
                        
                        const data = {
                            orderCreationId: orderDetails.order_order_id,
                            razorpayPaymentId: response.razorpay_payment_id,
                            razorpayOrderId: response.razorpay_order_id,
                            razorpaySignature: response.razorpay_signature,
                        };
                        verifyPayment(data, (response) => {
                            if (response?.status === 'success'){                                
                                props.upgradePlan();
                            }
                        })       
                        
                    },
                    prefill: {
                        name: props.account.username,
                        email: props.account.email                
                    },            
                    theme: {
                        color: "#000000",
                    },
                };                
                const paymentObject = new window.Razorpay(options);
                paymentObject.on('payment.failed', function (response){
                    alert(JSON.stringify(response.error.code));
                });
                paymentObject.open();
            }
            else {
                setLoading(false)
                return false;
            }
        })        

        
        
    }
    return (
        <Box>            
            <Button isLoading={loading} variant="solid" colorScheme="brand" onClick={displayRazorpay} >Upgrade to PRO</Button>
        </Box>

    )        
}

const Plans = () => {
    const authContext = useContext(AuthContext);    
    const account = getLocalAccounts().find(account => account.email === authContext.data.email)
    const [plan, setPlan] = useState(undefined)

    
    useEffect(() => {
        const checkPlan = callback => {
            authContext.getData('/account/plan', {}, function(response) {                
                callback(response.data)
            })
        }

        checkPlan((response) => {
            setPlan(response.message)
        })
    }, [])

    const upgradePlan = () => {
        setPlan('PRO')
    }

    const currentPlan = () => {
        return (
            <Box m={4}>
                <HStack spacing={4}>
                    <Heading size="md">Current Plan:</Heading>
                    <Badge variant="outline" colorScheme={plan === 'PRO' ? 'brand' : 'gray'} fontSize="md">{plan}</Badge>
                    {plan === 'FREE' && <UpgradeForm account={account} upgradePlan={upgradePlan}/>}
                </HStack>
                
            </Box>
        )
        
        
    }
    return (
        <VStack>
            <Box w="2xl" textAlign="center" spacing={4}>                
                {!plan && <Spinner size="xl" />}
                {plan && currentPlan()}                
            </Box>
        </VStack>
        
    )    
}

export default Plans
