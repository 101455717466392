import React, { useState } from 'react';
import { 
    Box,    
    Icon,
    Button, 
    FormControl,
    Input,        
    HStack,    
    Heading,
    Stack,
    Fade, 
    SimpleGrid,
    Text,
    FormLabel,
    Switch,
    Alert,
    AlertIcon,
    useToast,
    InputGroup,
    InputLeftElement
} from '@chakra-ui/react';
import { RiArrowLeftSLine, RiCheckFill} from 'react-icons/ri';

const WordInput = (props) => {    
    const [value, setValue] = useState('')
    const handleChange = (event) => {
        setValue(event.target.value)
        props.updateInput(props.index, event.target.value)        
    }
    return (
        //<Input onChange={handleChange} variant="flushed" type={"text"} />
        <InputGroup>
            <InputLeftElement color="gray.500" children={(props.index + 1)} />
            <Input variant="flushed" value={value} onChange={handleChange}  />            
        </InputGroup>
    )
}


const MnemonicForm = (props) => {    
    const toast = useToast();    
    const [inputs, setInputs] = useState(new Array(24).fill(''))
    
    const updateInput = (index, value) => {
        setInputs(inputs.map((input, i) => {
            if (index === i) return value;
            return input;
        }))
    }

    

    const handleSubmit = (event) => {
        event.preventDefault();
        props.handleRecoveryInput(inputs.join(' '), event)                
    }
    return (
        <form onSubmit={handleSubmit} >     
            <Stack spacing={4}>
            <Fade in={true}>
                <FormControl isRequired >
                    <Box textAlign="center">
                    <Heading size="sm">Recovery Verification</Heading>
                    <Text>Please enter your recovery phrase</Text>
                    <SimpleGrid mt={3} mb={3} minChildWidth="100px" spacing="10px" >{inputs.map((input, index) => <WordInput key={index} updateInput={updateInput} index={index} handleSubmit={handleSubmit}/>)}</SimpleGrid>
                    </Box>
                </FormControl>
                <br />                

                <HStack justify="end" mt={3}>
                    <Button 
                        variant="ghost" 
                        mr={3} 
                        onClick={props.back} 
                        leftIcon={<Icon as={RiArrowLeftSLine}/>} closeOnOverlayClick={false}>
                    Back
                    </Button>
                    <Button 
                        type="submit"  
                        colorScheme="brand" 
                        rightIcon={<Icon as={RiCheckFill}/>}                                        
                        isLoading={props.loading || false}
                    >Login</Button>                    
                </HStack>
            </Fade>       
            </Stack>
        </form>
    )
}

export default MnemonicForm;