import React, { useState, useContext } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,    
    ModalBody,
    ModalCloseButton,     
    useToast,        
} from '@chakra-ui/react';
import MnemonicForm from './MnemonicForm'

const LoginMnemonic = (props) => {
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} closeOnOverlayClick={false} isCentered size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Secret Phrase        
                </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <MnemonicForm handleRecoveryInput={props.handleRecoveryInput} onClose={props.onClose}/>
            </ModalBody>            
            </ModalContent>
        </Modal>
    )
}

export default LoginMnemonic;