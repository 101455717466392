import React, { useState, useContext, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,    
    ModalBody,
    ModalCloseButton,     
    useToast,    
    VStack,
    Divider,
    HStack,
    Button,
    Icon,
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react';

import { 
    passwordHash, 
    getUserKey, 
    getMasterKey, 
    getProtectedRecovery,     
    addLocalAccount,
    newMnemonic,
    mnemonicHash,
    passwordStrength
 } from '../../helpers/authUtils';

import { postData } from '../../helpers/fetchUtils';
import BasicInfo from './BasicInfo';
import Secrets from './Secrets';
import Recovery from './Recovery'
import Verification from './Verification'
import ParanoidModeSwitcher from './ParanoidSwitch'
import { AuthContext } from './../Context'
import { RiArrowLeftSLine, RiArrowRightSLine, RiCheckFill } from 'react-icons/ri';

const SignUpForm = (props) => {
    const context = useContext(AuthContext)
    const initialRef = React.useRef()
    const [loading, setLoading] = useState(false)
    const toast = useToast()
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')    
    const [mnemonic, setMnemonic] = useState(newMnemonic())
    const [saveRecovery, setSaveRecovery] = useState(true)
    const [strength, setStrength] = useState({})
    const [strengthColor, setStrengthColor] = useState("red")        
    
    const views = ['basic', 'advanced']
    const [view, setView] = useState(views[0])

    const [paranoidStep, setParanoidStep] = useState(0)
    const [typedMnemonic, setTypedMnemonic] = useState('')

    useEffect(() => {
        const str = passwordStrength(password, [email, username])        
        setStrength(str)     
        //console.log(str)   
        switch (str.score){
            case 0:
                setStrengthColor("red");
                break;
            case 1:
                setStrengthColor("orange");
                break;
            case 2:
                setStrengthColor("yellow");
                break;
            case 3:
                setStrengthColor("blue");
                break;
            case 4:
                setStrengthColor("green");
                break;
            default:
                setStrengthColor("red")
        }
    }, [password, email, username])   

    const signup = async () => {
        console.log('signup')
        setLoading(true)
        try {
            await passwordHash(password, email).then(async passwordHash => {                         
                await getUserKey(email, password).then(async userKey => {                    
                    await getMasterKey(mnemonic, email).then(async masterKey => {                                                                        
                        await getProtectedRecovery(userKey, mnemonic).then(async protectedRecovery => {
                            const mnemonicIntegrity = await mnemonicHash(mnemonic);
                            const payload = {
                                email:email,
                                username:username,
                                password: passwordHash.encoded,
                                recovery_hash:mnemonicIntegrity
                            }

                            if (saveRecovery){ payload.protectedRecovery = protectedRecovery}
                            console.log(payload)
                            //throw {message:'POST interrupt'};
                            postData('/signup/',payload).then(response => {
                                if (response.status === 'fail') {
                                    setLoading(false);
                                    toast({
                                        title: "Error",
                                        description: response.message,
                                        status: "error",
                                        duration: 2000,
                                        isClosable: true,
                                        position:"bottom-right"
                                    })        
                                }  
                                else {
                                    setLoading(false)
                                    toast({
                                        title: "Success",
                                        description: "Signed up!",
                                        status: "success",
                                        duration: 2000,
                                        isClosable: true,
                                        position:"bottom-right"
                                    })
                                    //createSession(response)
                                    addLocalAccount({
                                        email: email,
                                        username: username,
                                        protectedRecovery:protectedRecovery,
                                        mnemonicIntegrity:mnemonicIntegrity
                                    })
                                    //context.createSession(response)
                                    props.refreshAccounts();
                                    props.onClose();
                                }
                            }); 
                        })
                    })
                })
            })       
            
            
        } catch (err) {
            console.log(err);
            setLoading(false);
            toast({
                title: "Error.",
                description: err.message,
                status: "error",
                duration: 2000,
                isClosable: true,
                position:"bottom-right"
            })    
        }        
        
    }  

    const verifyMnemonic = () => {    
        console.log(mnemonic)
        console.log(typedMnemonic)           
        return typedMnemonic === mnemonic;
    }
   


    
    const handleSubmit = (event) => {        
        event.preventDefault();
        
        //switch view to advanced if switch is on
        if (!saveRecovery && view === 'basic') {                    
            setView('advanced')
            return false
        }
        
        //in advanced view, open verification accordion panel if closed
        if (view === 'advanced' && paranoidStep === 0) {
            console.log('go to verify')
            setParanoidStep(1)
            return false
        }
        
        //compare password and confirm password
        if (password !== password2) {
            toast({
                title: "Error",
                description: "Passwords don't match!",
                status: "error",
                duration: 2000,
                isClosable: true,
                position:"bottom-right"
            })   
            return false;
        }   

        //validate password minimum strength
        if (strength.score < 3) {
            toast({
                title: "Error",
                description: "Password is too weak!",
                status: "error",
                duration: 2000,
                isClosable: true,
                position:"bottom-right"
            })   
            return false;
        }

        //validate typed recovery if paranoid = On
        if (!saveRecovery && !verifyMnemonic()){
            toast({
                title: "Error",
                description: "Recovery phrase is incorrect!",
                status: "error",
                duration: 2000,
                isClosable: true,
                position:"bottom-right"
            })   
            return false;
        }

        signup()        
    }

    const basicSignup = () => {
        return (
            <Box maxH="75vh" overflowY="auto" px="1">
                <BasicInfo ref={initialRef} username={username} setUsername={setUsername} email={email} setEmail={setEmail} />
                <Divider/>
                <Secrets username={username} email={email} password={password} setPassword={setPassword} password2={password2} setPassword2={setPassword2} strength={strength} strengthColor={strengthColor} />
                <Divider/>
                <ParanoidModeSwitcher saveRecovery={saveRecovery} setSaveRecovery={setSaveRecovery}/>
            </Box>
        )
    }

    const paranoidSignup = () => {
        return (
            <Box maxH="75vh" overflowY="auto" px="1" w="full">                
                <Accordion index={paranoidStep} onChange={(expandedIndex) => setParanoidStep(expandedIndex)}>
                    <AccordionItem>
                        <h2>
                        <AccordionButton _expanded={{ fontWeight:"bold"}}>
                            <Box flex="1" textAlign="center">
                                Master Secret
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Recovery mnemonic={mnemonic} />
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                        <AccordionButton _expanded={{ fontWeight:"bold" }}>
                            <Box flex="1" textAlign="center">
                            Verify Master Secret
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                        <Verification mnemonic={mnemonic} setTypedMnemonic={setTypedMnemonic} active={paranoidStep === 1}/>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Box>
        )
    }

    const handleBack = (event) => {
        if (view === 'basic') props.onClose() 
        else if (view === 'advanced' && paranoidStep === 1) {
            setParanoidStep(0)           
        }
        else setView('basic')
        return true
    }

    return (
        
        <Modal isOpen={props.isOpen} onClose={props.onClose} initialFocusRef={initialRef} closeOnOverlayClick={false} isCentered size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Create a new account
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <form onSubmit={handleSubmit}>
                        <VStack>
                            {view === 'basic' && basicSignup()}
                            {view === 'advanced' && paranoidSignup()}

                            <HStack justify="end" mt={3} w="full">
                                <Button 
                                    variant="ghost" 
                                    mr={3} 
                                    onClick={handleBack} 
                                    leftIcon={<Icon as={RiArrowLeftSLine}/>} closeOnOverlayClick={false}                                    
                                    >
                                Back
                                </Button>
                                <Button 
                                    type="submit"  
                                    colorScheme="brand" 
                                    rightIcon={<Icon as={saveRecovery ? RiCheckFill : RiArrowRightSLine}/>}                                     
                                    isLoading={loading}
                                >{saveRecovery ? "Submit" : "Next"}</Button>                    
                            </HStack>         
                        </VStack>
                    </form>
            </ModalBody>            
            </ModalContent>
        </Modal>
    );
}

export default SignUpForm