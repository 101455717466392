import { VStack, Heading, Text, Box } from "@chakra-ui/react"

const MenuButton = ({ children, isLast, onClick, text, icon, active, ...rest }) => {
    return (
        <Box as="button" variant="link" w="100%" p="1" rounded="0" _hover={{ textDecoration: 'none', fontWeight: 'bold', cursor:"pointer", textShadow:"md" }} onClick={onClick} >            
            <VStack>
                <Heading size="md" my="2">{icon}</Heading>
                <Text fontSize="xs" color="gray.500"  >{text}</Text>
            </VStack>
        </Box>
    )
}

export default  MenuButton;
