export const colors = [
    {
        bg: 'inherit',
        text: 'inherit'
    },
    {
        bg: 'white',
        text: 'black'
    },
    {
        bg: 'black',
        text: 'white'
    },
    {
        bg: 'green',
        text: 'white'
    },
    {
        bg: 'blue',
        text: 'white'
    },
    {
        bg: 'red',
        text: 'white'
    },
    {
        bg: 'yellow',
        text: 'black'
    },
    {
        bg: 'purple',
        text: 'white'
    },
    {
        bg: 'orange',
        text: 'white'
    },
    {
        bg: 'pink',
        text: 'black'
    }
]
