import React, { useState, useContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,  
} from "react-router-dom";
import {
  ChakraProvider,      
  SlideFade
} from '@chakra-ui/react';

import Auth from './Auth/index';
import Vault from './Main/index';
import { getSession } from './helpers/authUtils'
import theme from './customTheme'
import { AuthContext } from './Auth/Context'
import { Fonts } from "./Fonts"
import Loader from './Loader'
const axios = require('axios').default;



function App() {  
  const [userSession, setUserSession] = useState(undefined)
  const [loading, setLoading] = useState(false)
  
  const getAuthHeader = () => {
    return {
      Authorization: 'Bearer ' + userSession.access
    }
  }
  
  const createSession = (data) => {       
      console.log('created session')           
      setUserSession(data)        
  }

  const refreshSession = () => {console.log('refresh')}

  const endSession = () => {
    console.log('end session')
    setUserSession(undefined)    
  }

  //custom axios instance
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10000    
  });

  const getData = async (url, params, callback) => {   
    setLoading(true);
    api.get(url, {
      params: params,
      headers: getAuthHeader()
    })
    .then(function (response) {      
      callback (response);
    })
    .catch(function (error) {
      console.log(error);
      
    })
    .then(function () {
      setLoading(false);
    });
  }

  const postData = async (url, data, callback) => {    
    setLoading(true);
    api.post(url, data, {      
      headers: getAuthHeader()
    })
    .then(function (response) {
      console.log(response);
      callback (response);
    })
    .catch(function (error) {
      console.log(error);
      
    })
    .then(function () {
      setLoading(false);
    });
  }

  const putData = async (url, data) => {    
    setLoading(true);
    api.put(url, data, {      
      headers: getAuthHeader()
    })
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
      
    })
    .then(function () {
      setLoading(false);
    });
  }


  const deleteData = async (url, data) => { 
    setLoading(true);       
    api.delete(url, {      
      headers: getAuthHeader(),
      data:data
    })
    .then(function (response) {      
      return response;
    })
    .catch(function (error) {
      console.log(error);
      
    })
    .then(function () {
      setLoading(false);
    });
  }

  //register interceptor
  api.interceptors.response.use(
    function(response) {
      // If the request succeeds, return response
      return response
    },
    function(error) {
      const errorResponse = error.response
      console.log('error', error)
      if (errorResponse && isTokenExpiredError(errorResponse)) {
        return resetTokenAndReattemptRequest(error)
      }
      // If the error is due to other reasons, we just throw it back to axios
      return Promise.reject(error)
    }
  )

  
  function isTokenExpiredError(errorResponse) {
    // logic to determine if the error is due to JWT token expired returns a boolean value
    console.log(errorResponse)
    return true;
  }
  
  let isAlreadyFetchingAccessToken = false;
  
  // This is the list of waiting requests that will retry after the JWT refresh complete
  let subscribers = [];
    
  async function resetTokenAndReattemptRequest(error) {
      try {
          const { response: errorResponse } = error;
          const resetToken = userSession.refresh;
          if (!resetToken) {
              // We can't refresh, throw the error anyway
              return Promise.reject(error);
          }
          /* Proceed to the token refresh procedure
          We create a new Promise that will retry the request,
          clone all the request configuration from the failed
          request in the error object. */
          const retryOriginalRequest = new Promise(resolve => {
          /* We need to add the request retry to the queue
          since there another request that already attempt to
          refresh the token */
          addSubscriber(access_token => {
            errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
            resolve(axios(errorResponse.config));
          });
        });
        if (!isAlreadyFetchingAccessToken) {
              isAlreadyFetchingAccessToken = true;
              const response = await axios({
                  method: 'post',
                  url: process.env.REACT_APP_API_URL + '/token/refresh/',
                  data: {
                      refresh: resetToken // Just an example, your case may vary
                  }
              });
          if (!response.data) {
            return Promise.reject(error);
          }
          const newToken = response.data.access;
          let newSessionData = userSession;
          newSessionData.access = response.data.access;
          newSessionData.refresh = response.data.refresh;
          setUserSession(newSessionData)
          isAlreadyFetchingAccessToken = false;
          onAccessTokenFetched(newToken);
        }
        return retryOriginalRequest;
      } catch (err) {
          return Promise.reject(err);
      }
  }
    
  function onAccessTokenFetched(access_token) {
      // When the refresh is successful, we start retrying the requests one by one and empty the queue
      subscribers.forEach(callback => callback(access_token));
      subscribers = [];
  }
    
  function addSubscriber(callback) {
      subscribers.push(callback);
  }
  //const authContext = useContext(AuthContext);      

  if (!userSession) {
    return (
      <AuthContext.Provider value ={{data:userSession, createSession, refreshSession, endSession}}>
        <ChakraProvider theme={theme}>      
        <Fonts/>    
          <Auth setUserSession={setUserSession}/>
        </ChakraProvider>
      </AuthContext.Provider>
    )
  }
  return (
    <AuthContext.Provider value ={{data:userSession, createSession, refreshSession, endSession, getData, postData, putData, deleteData}}>
      <SlideFade unmountOnExit direction="right" in={loading} style={{ zIndex: 10 }}>
        <Loader/>
      </SlideFade>
      <ChakraProvider theme={theme}>        
        <Fonts/>
        <Router>            
          <Vault/>        
          <Switch>
            <Route path="/vault">                    
              <Vault/>          
            </Route>
          </Switch>
      </Router>
      </ChakraProvider>  
    </AuthContext.Provider>
  );
}

export default App;
